import React from "react";
import { useCookies } from "react-cookie";
import { Modal, ModalBody } from "reactstrap";
import { notifyInfo } from "../../utils/notifyToast";
import { useHotkeys } from "react-hotkeys-hook";
import "./styles.css";

const ClearCartModal = ({ isOpen, toggleOpen }) => {
  const [, setCookies] = useCookies(["cart"]);

  const onClear = () => {
    setCookies(
      "cart",
      {
        packages: [],
        coupon: null,
        coupon_id: "",
      },
      {
        path: "/",
      }
    );
    notifyInfo("Cart cleared!");
    toggleOpen();
  };

  const onCancel = () => {
    toggleOpen();
  };

  useHotkeys("enter", !document.getElementById("pin") && isOpen && onClear);

  return (
    <Modal centered isOpen={isOpen} toggle={toggleOpen} size="md">
      <ModalBody className="px-5 py-4">
        <h4 className="mb-4">Are you sure you want to clear the cart?</h4>

        <div className="d-flex gap-2 cart__clear-buttons">
          <button
            className="header_menu__item mt-3 w-50"
            style={{ backgroundColor: "red" }}
            onClick={onCancel}
          >
            No, cancel!
          </button>
          <button className="header_menu__item mt-3 w-50" onClick={onClear}>
            Yes, clear the cart.
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ClearCartModal;
