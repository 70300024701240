import React, { useMemo, useState } from "react";
import { Card, CardFooter, CardHeader } from "reactstrap";
import UserDetails from "./UserDetails";
import { FETCH_PROFILE_SUCCESS } from "../store/types";
import { useDispatch, useSelector } from "react-redux";
import CartBodyList from "./CartBodyList";
import { useCookies } from "react-cookie";
import NewCustomerForm from "./NewCustomerForm";
import ApplyCoupon from "./ApplyCoupon";
import BookingModal from "./BookingModal";
import { useHotkeys } from "react-hotkeys-hook";

import UserProfileHeader from "./UserProfileHeader";

const NewCustomer = (props) => {
  return (
    <button className="header_menu__item w-100" {...props}>
      New Customer
    </button>
  );
};

function Cart() {
  const { couponValidityData, userProfile } = useSelector((state) => ({
    userProfile: state.authUser.userDetails,
    couponValidityData: state.discover.couponValidityData,
  }));
  const [isOpen, setIsOpen] = useState(false);
  const [isClearOpen, setIsClearOpen] = useState(false);
  const [isCouponOpen, setCouponOpen] = useState(false);
  const [newFormOpen, setNewFormOpenState] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const toggleNewOpen = () => {
    setNewFormOpenState(!newFormOpen);
  };
  const toggleClearOpen = () => {
    setIsClearOpen(!isClearOpen);
  };
  const toggleCouponOpen = () => {
    setCouponOpen(!isCouponOpen);
  };

  const { token } = useSelector((state) => ({
    token: state.authUser.uid,
    bookingPackageStatus: state.discover.bookingPackageStatus,
  }));

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["cart"]);

  const bookAllCart = () => {
    // let _cartPackages = cookies.cart?.packages || [];
    // if (!_cartPackages.length) return;
    // if (!userProfile.id?.length) return;

    // _cartPackages = _cartPackages.map((pack) => {
    // 	if (pack["time_slot_data"]) delete pack["time_slot_data"];
    // 	return pack;
    // });
    // dispatch(
    // 	bookPackages({ ...cookies.cart, packages: _cartPackages }, userProfile.id, token, () => {
    // 		setCookie("cart", { packages: [], coupon: null }, { path: "/" });
    // 	})
    // );
    toggleCouponOpen();
  };

  const clearCustomer = () => {
    dispatch({
      type: FETCH_PROFILE_SUCCESS,
      payload: {
        mobile: "",
        name: "",
        email: "",
        id: "",
      },
    });
  };

  const cartItemCount = useMemo(() => {
    return cookies.cart?.packages?.length || 0;
  }, [cookies.cart?.packages]);

  const total = useMemo(() => {
    if (!cookies.cart?.packages?.length) return 0;
    let _total = 0;
    for (let pack of cookies.cart.packages) _total += pack.total;
    return _total;
  }, [cookies.cart?.packages]);

  const userHasData = useMemo(() => {
    return userProfile.name?.length || userProfile.mobile?.length;
  }, [userProfile.name, userProfile.mobile]);

  const couponDiscountedAmount = useMemo(() => {
    if (!couponValidityData) return 0;
    const deductedTotalValue = couponValidityData
      .map((cpnPkg) => cpnPkg.total || 0)
      .reduce((prev, curr) => prev + curr, 0);
    return Number(deductedTotalValue);
  }, [couponValidityData]);

  useHotkeys("f1", () => {
    if (userHasData && cartItemCount && localStorage.getItem("shift")) {
      bookAllCart();
    }
  });

  useHotkeys("N", toggleNewOpen);

  return (
    <>
      <div className="cart">
        <NewCustomerForm
          isOpen={newFormOpen}
          toggleNewUserDetails={toggleNewOpen}
        />
        <Card className="cart__card h-100" style={{ overflowY: "hidden" }}>
          <CardHeader className="border-0 text-center header-card">
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <NewCustomer onClick={toggleNewOpen} />
              <button
                className="header_menu__item w-30"
                onClick={clearCustomer}
              >
                Clear
              </button>
            </div>
            <UserProfileHeader
              toggleClearOpen={toggleClearOpen}
              toggleOpen={toggleOpen}
            />
          </CardHeader>
          <CartBodyList />
          <CardFooter className="border-0 px-0 pt-4 pb-0 w-100">
            <ApplyCoupon isOpen={isCouponOpen} toggleOpen={toggleCouponOpen} />
            <div className="text-center">
              <button
                style={{
                  height: 60,
                  backgroundColor:
                    (!userHasData ||
                      !cartItemCount ||
                      !localStorage.getItem("shift")) &&
                    "#C0C0C0",
                  cursor:
                    (!userHasData ||
                      !cartItemCount ||
                      !localStorage.getItem("shift")) &&
                    "revert",
                }}
                className="header_menu__item w-100"
                onClick={bookAllCart}
                disabled={
                  !userHasData ||
                  !cartItemCount ||
                  !localStorage.getItem("shift")
                }
              >
                Book Now
              </button>
            </div>
          </CardFooter>
        </Card>
      </div>
      <BookingModal />
      <UserDetails isOpen={isOpen} toggleUserDetails={toggleOpen} />
    </>
  );
}

export default Cart;
