import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
} from "reactstrap";
import { useHotkeys } from "react-hotkeys-hook";
import {
  getUserProfile,
  updateUserProfile,
  addDocuments,
  deleteDocuments,
  getDocuments,
} from "../store/actions/authActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import countryList from "react-select-country-list";
import { getCountryCallingCode } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { notifySuccess } from "../utils/notifyToast";

function UserDetails(props) {
  let { isOpen, toggleUserDetails } = props;

  const { profileFetchStatus, profileUpdateStatus, token, userDetails } =
    useSelector(({ authUser }) => ({
      token: authUser.uid,
      userDetails: authUser.userDetails,
      profileFetchStatus: authUser.profileFetchStatus,
      profileUpdateStatus: authUser.profileUpdateStatus,
    }));

  const dispatch = useDispatch();

  const [localUserProfile, setLocalUserProfile] = useState(userDetails);
  const [countryCode, setCountryCode] = useState("AE");
  const [gender, setGender] = useState("male");
  const [language, setLanguage] = useState("");

  const [nature, setNature] = useState("");
  const [tourist, setTourist] = useState("");
  const [resident, setResident] = useState("");
  const [expat, setExpat] = useState("");
  const [natureOfTourist, setNatureOfTourist] = useState("");
  const [corporate, setCorporate] = useState("");
  const [stakeHolder, setStakeHolder] = useState("");
  const [government, setGovernment] = useState("");
  const [education, setEducation] = useState("");
  const [enableTourist, setEnableTourist] = useState(false);
  const [enableResident, setEnableResident] = useState(false);
  const [enableExpat, setEnableExpat] = useState(false);
  const [enableCorporate, setEnableCorporate] = useState(false);
  const [enableStackHolder, setEnableStackHolder] = useState(false);
  const [enableGovernment, setEnableGovernment] = useState(false);
  const [enableEducation, setEnableEducation] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [file, setFile] = useState([]);
  const [natureNotes, setNatureNotes] = useState("");
  const [touristNotes, setTouristNotes] = useState("");
  const [residentNotes, setResidentNotes] = useState("");
  const [expatNotes, setExpatNotes] = useState("");
  const [natureOfTouristNotes, setNatureOfTouristNotes] = useState("");
  const [corporateNotes, setCorporateNotes] = useState("");
  const [stakeHolderNotes, setStakeHolderNotes] = useState("");
  const [governmentNotes, setGovernmentNotes] = useState("");
  const [educationNotes, setEducationNotes] = useState("");

  const fileColumns = [
    { name: "Document Name", cell: (row) => row.document },
    {
      name: "View Link",
      cell: (row) => (
        <button className="header_menu__item">
          <a
            href={`${process.env.REACT_APP_API}files/${row.document_id}`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            View
          </a>
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="header_menu__item"
          onClick={() => handleDocumentDelete(row)}
        >
          Delete
        </button>
      ),
    },
  ];

  useEffect(() => {
    console.log(userDetails);
    setCorporateNotes(userDetails.meta_info?.corporate_notes);
    setCorporate(userDetails.meta_info?.corporate_info);
    setExpatNotes(userDetails.meta_info?.expat_notes);
    setExpat(userDetails.meta_info?.expat_info);
    setGovernmentNotes(userDetails.meta_info?.government_notes);
    setGovernment(userDetails.meta_info?.government_info);
    // setEnableCorporate(userDetails.meta_info.is_corporate === "1");
    // setEnableExpat(userDetails.meta_info.is_expat === "1");
    // setEnableGovernment(userDetails.meta_info.is_government === "1");
    // setEnableResident(userDetails.meta_info.is_resident === "1");
    // setEnableStackHolder(userDetails.meta_info.is_stakeholder === "1");
    // setEnableTourist(userDetails.meta_info.is_tourist === "1");
    setLanguage(userDetails.meta_info?.language);
    setNature(userDetails.meta_info?.nature_info);
    setNatureNotes(userDetails.meta_info?.nature_notes);
    setNatureOfTourist(userDetails.meta_info?.nature_of_tourist);
    setNatureOfTouristNotes(userDetails.meta_info?.nature_of_tourist_notes);
    setResident(userDetails.meta_info?.resident_info);
    setResidentNotes(userDetails.meta_info?.resident_notes);
    setStakeHolder(userDetails.meta_info?.stakeholder_info);
    setStakeHolderNotes(userDetails.meta_info?.stakeholder_notes);
    setTourist(userDetails.meta_info?.tourist_info);
    setTouristNotes(userDetails.meta_info?.tourist_notes);
    setGender(userDetails.meta_info?.gender);
    if (!userDetails.mobile?.length) {
      setLocalUserProfile(userDetails);
      return;
    }
    const phone = parsePhoneNumber(userDetails.mobile);

    if (Object.prototype.hasOwnProperty("nationalNumber")) {
      setLocalUserProfile({
        ...userDetails,
        mobile: phone.nationalNumber,
      });
      setCountryCode(phone.country);
    } else {
      setLocalUserProfile({
        ...userDetails,
      });
      setCountryCode("AE");
    }
    if (isOpen)
      getDocuments(userDetails.id, token, (list) => {
        setFile(list.documents);
      });
  }, [userDetails]);

  const updateUserLocalProfile = (field, value) => {
    setLocalUserProfile({ ...localUserProfile, [field]: value });
  };

  const onPhoneSubmit = () => {
    if (!localUserProfile.mobile.length) {
      alert("Please enter the phone number.");
      return;
    }
    if (!countryCode.length) {
      alert("Please choose your country.");
      return;
    }
    const countryPhoneCode = getCountryCallingCode(countryCode);

    return dispatch(
      getUserProfile(`${countryPhoneCode}${localUserProfile.mobile}`, token)
    );
  };

  const onProfileUpdate = () => {
    let _userData = { mobile: localUserProfile.mobile, meta_info: {} };
    // if (localUserProfile.name !== userDetails.name) {
    _userData.name = localUserProfile.name;
    // }
    // if (localUserProfile.email !== userDetails.email) {
    _userData.email = localUserProfile.email;
    // }
    _userData.meta_info.gender = gender;
    _userData.meta_info.language = language;
    _userData.meta_info.nature_info = nature;
    _userData.meta_info.is_tourist = nature === "Tourist" ? "1" : "0";
    _userData.meta_info.tourist_info = tourist;
    _userData.meta_info.tourist_notes = touristNotes;
    _userData.meta_info.is_resident = nature === "Resident" ? "1" : "0";
    _userData.meta_info.resident_info = resident;
    _userData.meta_info.resident_notes = residentNotes;
    _userData.meta_info.is_expat = nature === "Expat" ? "1" : "0";
    _userData.meta_info.expat_info = expat;
    _userData.meta_info.expat_notes = expatNotes;
    _userData.meta_info.nature_of_tourist = natureOfTourist;
    _userData.meta_info.nature_of_tourist_notes = natureOfTouristNotes;
    _userData.meta_info.is_corporate = nature === "Corporate" ? "1" : "0";
    _userData.meta_info.corporate_info = corporate;
    _userData.meta_info.corporate_notes = corporateNotes;
    _userData.meta_info.is_stakeholder = nature === "Stakeholder" ? "1" : "0";
    _userData.meta_info.stakeholder_info = stakeHolder;
    _userData.meta_info.stakeholder_notes = stakeHolderNotes;
    _userData.meta_info.is_government = nature === "Government" ? "1" : "0";
    _userData.meta_info.government_info = government;
    _userData.meta_info.government_notes = governmentNotes;
    // if (!Object.keys(_userData).length) {
    //   return;
    // }
    return dispatch(updateUserProfile(userDetails.id, _userData, token));
  };

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;
    // const [{ size, name }] = files;
    // const maxSize = 50000;

    // if (size < maxSize) {

    // } else {
    // }

    var fileArr = [];
    Object.keys(files).map((file, key) => fileArr.push(files[key]));
    addDocuments(userDetails.id, fileArr, token, (list) => {
      setFile(list.documents);
      notifySuccess(list.msg);
    });
  };

  const handleDocumentDelete = (row) => {
    deleteDocuments(userDetails.id, row.document_id, token, (list) => {
      setFile(list.documents);
      notifySuccess(list.msg);
    });
  };

  useHotkeys(
    "enter",
    !document.getElementById("pin") &&
      isOpen &&
      profileFetchStatus === "success"
      ? onProfileUpdate
      : onPhoneSubmit
  );

  return (
    <div>
      <Modal
        centered
        isOpen={isOpen}
        toggle={toggleUserDetails}
        // size="lg"
        style={{ maxWidth: "750px", width: "100%" }}
        // style={{ width: "90vw", height: "90vh" }}
      >
        <ModalHeader>
          <div style={{ display: "flex" }}>
            <span>Customer Details </span>
            <button
              className="header_menu__item"
              style={{ marginLeft: "27rem", height: "2.5rem" }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "Show More"}
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              profileFetchStatus === "success"
                ? onProfileUpdate()
                : onPhoneSubmit();
            }}
          >
            <Label className="mt-2">Phone Number</Label>
            <InputGroup>
              <InputGroupText>
                <Input
                  readOnly={profileFetchStatus === "success"}
                  style={{ width: 330 }}
                  value={countryCode}
                  type="select"
                  onChange={(e) => {
                    if (profileFetchStatus !== "success")
                      setCountryCode(e.target.value);
                  }}
                >
                  {countryList()
                    .getData()
                    .map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Input>
              </InputGroupText>
              <Input
                value={localUserProfile.mobile}
                onChange={(e) => {
                  updateUserLocalProfile("mobile", e.target.value);
                }}
                type="tel"
                required
                readOnly={profileFetchStatus === "success"}
              />
            </InputGroup>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Label className="mt-2">Name</Label>
                <Input
                  style={{ width: 350 }}
                  value={localUserProfile.name}
                  onChange={(e) => {
                    updateUserLocalProfile("name", e.target.value);
                  }}
                  type="text"
                />
              </div>
              <div>
                <Label className="mt-2">Email</Label>
                <Input
                  style={{ width: 350 }}
                  value={localUserProfile.email}
                  onChange={(e) => {
                    updateUserLocalProfile("email", e.target.value);
                  }}
                  type="email"
                />
              </div>
            </div>
            {showMore && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Label className="mt-2">Gender</Label>
                    <Input
                      style={{ width: 350 }}
                      value={gender}
                      type="select"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      {["male", "female"].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div>
                    <Label className="mt-2">Language</Label>
                    <Input
                      style={{ width: 350 }}
                      value={language}
                      type="select"
                      onChange={(e) => setLanguage(e.target.value)}
                    >
                      {[
                        "Arabic",
                        "English",
                        "Mandarin",
                        "Hindi",
                        "Spanish",
                        "French",
                        "Bengali",
                        "Russian",
                        "Portuguese",
                        "Indonesian",
                      ].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Label className="mt-2">Nature of Tourist</Label>
                    <Input
                      style={{ width: 350 }}
                      value={natureOfTourist}
                      type="select"
                      onChange={(e) => setNatureOfTourist(e.target.value)}
                    >
                      {[
                        "",
                        "Individual/Couple",
                        "Family",
                        "Group",
                        "MICE",
                        "Cruises",
                      ].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Input>
                    <Input
                      value={natureOfTouristNotes}
                      placeholder="Extra info - Notes Section"
                      onChange={(e) => {
                        setNatureOfTouristNotes(e.target.value);
                      }}
                      type="text"
                      className="mt-2"
                      style={{ width: 350 }}
                    />
                  </div>
                  <div>
                    <Label className="mt-2">Nature</Label>
                    <Input
                      style={{ width: 350 }}
                      value={nature}
                      type="select"
                      onChange={(e) => setNature(e.target.value)}
                    >
                      {[
                        "",
                        "Resident",
                        "Tourist",
                        "Corporate",
                        "Education",
                        "Marketing",
                        "Stakeholder",
                        "Government",
                      ].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div>
                  {nature === "Tourist" && (
                    <div>
                      <Label className="mt-2">Tourist</Label>
                      <Input
                        style={{ width: 350 }}
                        value={tourist}
                        type="select"
                        onChange={(e) => setTourist(e.target.value)}
                      >
                        {[
                          "",
                          "Nationality",
                          "Hotel",
                          "Demographic(Couple, Family)",
                          "Nature of Tourist",
                        ].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Input>
                      <Input
                        value={touristNotes}
                        placeholder="Extra info - Notes Section"
                        onChange={(e) => {
                          setTouristNotes(e.target.value);
                        }}
                        type="text"
                        className="mt-2"
                        style={{ width: 350 }}
                      />
                    </div>
                  )}
                  {nature === "Resident" && (
                    <div>
                      <Label className="mt-2">Resident</Label>
                      <Input
                        style={{ width: 350 }}
                        value={resident}
                        type="select"
                        onChange={(e) => setResident(e.target.value)}
                      >
                        {["", "Emirati", "Expact"].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Input>
                      <Input
                        value={residentNotes}
                        placeholder="Extra info - Notes Section"
                        onChange={(e) => {
                          setResidentNotes(e.target.value);
                        }}
                        type="text"
                        className="mt-2"
                        style={{ width: 350 }}
                      />
                    </div>
                  )}
                  {nature === "Expat" && (
                    <div>
                      <Label className="mt-2">Expat</Label>
                      <Input
                        style={{ width: 350 }}
                        value={expat}
                        type="select"
                        onChange={(e) => setExpat(e.target.value)}
                      >
                        {["", "Nationality"].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Input>
                      <Input
                        value={expatNotes}
                        placeholder="Extra info - Notes Section"
                        onChange={(e) => {
                          setExpatNotes(e.target.value);
                        }}
                        type="text"
                        className="mt-2"
                        style={{ width: 350 }}
                      />
                    </div>
                  )}
                  {nature === "Education" && (
                    <div>
                      <Label className="mt-2">Education</Label>
                      <Input
                        style={{ width: 350 }}
                        value={education}
                        type="select"
                        onChange={(e) => setEducation(e.target.value)}
                      >
                        {["", "School", "Universities"].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Input>
                      <Input
                        value={educationNotes}
                        placeholder="Extra info - Notes Section"
                        onChange={(e) => {
                          setEducationNotes(e.target.value);
                        }}
                        type="text"
                        className="mt-2"
                        style={{ width: 350 }}
                      />
                    </div>
                  )}
                  {nature === "Corporate" && (
                    <div>
                      <Label className="mt-2">Corporate</Label>
                      <Input
                        style={{ width: 350 }}
                        value={corporate}
                        type="select"
                        onChange={(e) => setCorporate(e.target.value)}
                      >
                        {["", "Team Building", "Incentive"].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Input>
                      <Input
                        value={corporateNotes}
                        placeholder="Extra info - Notes Section"
                        onChange={(e) => {
                          setCorporateNotes(e.target.value);
                        }}
                        type="text"
                        className="mt-2"
                        style={{ width: 350 }}
                      />
                    </div>
                  )}
                  {nature === "Stakeholder" && (
                    <div>
                      <Label className="mt-2">Stakeholder</Label>
                      <Input
                        style={{ width: 350 }}
                        value={stakeHolder}
                        type="select"
                        onChange={(e) => setStakeHolder(e.target.value)}
                      >
                        {["", "SCTDA"].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Input>
                      <Input
                        value={stakeHolderNotes}
                        placeholder="Extra info - Notes Section"
                        onChange={(e) => {
                          setStakeHolderNotes(e.target.value);
                        }}
                        type="text"
                        className="mt-2"
                        style={{ width: 350 }}
                      />
                    </div>
                  )}
                  {nature === "Government" && (
                    <div>
                      <Label className="mt-2">Government</Label>
                      <Input
                        style={{ width: 350 }}
                        value={government}
                        type="select"
                        onChange={(e) => setGovernment(e.target.value)}
                      >
                        {["", "SHUROOQ", "Other SHJ Governtment entitites"].map(
                          (item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          )
                        )}
                      </Input>
                      <Input
                        value={governmentNotes}
                        placeholder="Extra info - Notes Section"
                        onChange={(e) => {
                          setGovernmentNotes(e.target.value);
                        }}
                        type="text"
                        className="mt-2"
                        style={{ width: 350 }}
                      />
                    </div>
                  )}
                  {/* <FormGroup>
                        <Input
                          className="mt-2"
                          type="file"
                          id="exampleCustomFileBrowser"
                          name="customFile"
                          label={"choose an image file"}
                          onChange={handleFileChange}
                          multiple
                        />
                      </FormGroup>
                      <DataTable
                        highlightOnHover
                        columns={fileColumns}
                        data={file}
                      /> */}
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <button
                type="button"
                className="header_menu__item mt-3"
                style={{ width: "25%", backgroundColor: "red" }}
                onClick={toggleUserDetails}
              >
                Close
              </button>
              <button
                type="submit"
                className="header_menu__item mt-3"
                style={{ width: "75%" }}
                // onClick={
                //   profileFetchStatus === "success"
                //     ? onProfileUpdate
                //     : onPhoneSubmit
                // }
              >
                {profileUpdateStatus === "requested" ? (
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : null}
                {profileFetchStatus === "success"
                  ? "Update Profile"
                  : "Load Profile"}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default UserDetails;
