import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DataTable from "react-data-table-component";
import { IoFastFoodOutline, IoBus } from "react-icons/io5";
import { GoPackage } from "react-icons/go";

const getStatus = (status) => {
  if (status === "1") return "success";
  if (status === "2") return "failed";
  return "pending";
};

const PaymentPurchaseData = ({ purchaseData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [detailSeek, setDetailSeek] = useState();

  const purchaseDataTableModel = [
    //   {
    //     name: "ID",
    //     selector: (row) => row.id,
    //   },
    {
      name: "Purchase No.",
      selector: (row) => row.purchase_number,
    },
    {
      name: "Packages",
      selector: (row) => row.items?.packages?.length || 0,
      center: true,
    },
    {
      name: "Total Amount",
      cell: (row) => <div>AED {Number(row.total) || 0}</div>,
      center: true,
    },
    {
      name: "Attendees",
      cell: (row) => (
        <div className="d-flex justify-content-between px-2">
          {row.items.packages && row.items.packages.length && (
            <div className="d-flex justify-content-start">
              <span className="my-2 me-2 item__details">
                <i class="fa fa-user" aria-hidden="true"></i>
              </span>
              <span className="my-2 item__details">
                {row.items.packages[0].adult_qty}
              </span>
            </div>
          )}
          {row.items.packages && row.items.packages.length && (
            <div
              className="d-flex justify-content-start"
              style={{ marginLeft: "6px" }}
            >
              <span className="my-2 me-2 item__details">
                <i class="fa fa-child" aria-hidden="true"></i>
              </span>
              <span className="my-2 item__details">
                {row.items.packages[0].child_qty}
              </span>
            </div>
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Others",
      cell: (row) => (
        <div className="d-flex justify-content-between px-2">
          {row.items.packages &&
            row.items.packages[0].subpackages_id?.length && (
              <div className="d-flex justify-content-start">
                <span className="my-2 me-2 item__details">
                  <GoPackage size={16} />
                </span>
              </div>
            )}
          {row.items.packages && row.items.packages[0].transport_id?.length && (
            <div className="d-flex justify-content-start">
              <span className="my-2 me-2 item__details">
                <IoBus size={16} />
              </span>
            </div>
          )}
          {row.items.packages && row.items.packages[0].meals_qty?.[0] && (
            <div className="d-flex justify-content-start">
              <span className="my-2 me-2 item__details">
                <IoFastFoodOutline size={16} />
              </span>
            </div>
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span className={`purchase__status ${getStatus(row.status)}`}>
          {getStatus(row.status)}
        </span>
      ),
      center: true,
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="header_menu__item"
          onClick={() => {
            setDetailSeek(row);
            setIsOpen(true);
          }}
        >
          Details
        </button>
      ),
    },
  ];
  purchaseData = purchaseData.filter((purchase) =>
    purchase.items?.hasOwnProperty("packages")
  );
  return (
    <div className="purchase__data mt-5">
      <h5>Purchase Data</h5>
      <DataTable
        columns={purchaseDataTableModel}
        data={purchaseData}
        onRowClicked={(row) => {
          console.log(row);
          setDetailSeek(row);
          setIsOpen(true);
        }}
      />
      <Modal
        centered
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        size="md"
        scrollable
      >
        <ModalBody>
          <div className="booking-data">
            <div
              className="action row"
              style={{ marginRight: 0, marginLeft: 0, textAlign: "center" }}
            >
              {/* <div className="row counter total">
                <div className="col-md-12 col-12 coupon_title">
                  <p>AED {detailSeek?.total}</p>
                </div>
              </div> */}
              <div className="row counter">
                {detailSeek?.items.packages.map((item, id) => {
                  return (
                    <>
                      <div
                        className="col-md-12 col-12 mt-3 mb-4 align-center package_title"
                        style={{ fontSize: 18 }}
                      >
                        <p>
                          <b>
                            {item.package_info.package_name} (AED {item.total})
                          </b>
                        </p>
                      </div>
                      {(item.adult_qty > 0 || item.child_qty > 0) && (
                        <>
                          <h6
                            className="align-left"
                            style={{ fontWeight: "bold" }}
                          >
                            Tickets :
                          </h6>
                          <div className="col-md-6 col-6 mb-2 align-left">
                            <p>Adult : {item.adult_qty}</p>
                            <p>Child : {item.child_qty}</p>
                          </div>
                          <div className="col-md-6 col-6 align-right">
                            <p>
                              AED{" "}
                              {item.adult_price * item.adult_qty +
                                item.child_price * item.child_qty}
                            </p>
                          </div>
                          <hr></hr>
                        </>
                      )}
                      {item.transport_id && (
                        <>
                          <h6
                            className="align-left"
                            style={{ fontWeight: "bold" }}
                          >
                            People for Transport :
                          </h6>
                          <div className="col-md-6 col-6 mb-2 align-left">
                            <p>{item.transport_name}</p>
                          </div>
                          <div className="col-md-6 col-6 align-right">
                            <p>AED {item.transport_price}</p>
                          </div>
                          <hr></hr>
                        </>
                      )}
                      {item.meal_label?.length > 0 && (
                        <h6
                          className="align-left"
                          style={{ fontWeight: "bold" }}
                        >
                          Meals :
                        </h6>
                      )}
                      {item.meal_label?.map((meal, id) => (
                        <>
                          <div className="col-md-6 col-6 align-left">
                            <p>
                              {meal} : {item.meals_qty[id]}
                            </p>
                          </div>
                          {!id && (
                            <div className="col-md-6 col-6 align-right">
                              <p>AED {item.meal_price}</p>
                            </div>
                          )}
                        </>
                      ))}
                      {item.coupon_name && (
                        <>
                          <h6
                            className="col-md-6 col-6 mb-2 align-left"
                            style={{ fontWeight: "bold" }}
                          >
                            Coupon :
                          </h6>
                          <div className="col-md-6 col-6 align-right">
                            <p>{item.coupon_name}</p>
                          </div>
                          <hr></hr>
                        </>
                      )}
                      {/* <hr></hr> */}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentPurchaseData;
