import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { getDayClosureDetails } from "../store/actions/discoverActions";
import * as XLSX from "xlsx";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

function DayClosureHistoryDetails() {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [dayClosureDetails, setDayClosureDetails] = useState();
  const { token } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));

  useEffect(() => {
    getDayClosureDetails(
      setLoading,
      token,
      params.day_closure_id,
      (response) => {
        console.log(response);
        setDayClosureDetails(response);
      }
    );
  }, []);

  const downloadReport = () => {
    if (dayClosureDetails) {
      const htmlContent = dayClosureDetails;
      var endTime = "";

      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;

      const tables = tempDiv.querySelectorAll("table");

      const wb = XLSX.utils.book_new(); // Create a new workbook

      tables.forEach((table, index) => {
        const wsData = [[]];

        // Extract table data
        table.querySelectorAll("tr").forEach((row, idx) => {
          const rowData = [];
          if (idx === 1 && row.querySelectorAll("th")[1]) {
            endTime = "-" + row.querySelectorAll("th")[1]?.textContent.trim();
          }
          row.querySelectorAll("td, th").forEach((cell) => {
            rowData.push(cell.textContent.trim());
          });
          wsData.push(rowData);
        });

        // Filter out columns without data
        const filteredData = filterColumns(wsData);

        // Create a worksheet
        const ws = XLSX.utils.aoa_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(wb, ws, index ? "Details" : "Report");
      });

      // Convert the workbook to Excel file format
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `day_closure${endTime}.xlsx`);
      link.click();
    }
  };

  const filterColumns = (data) => {
    const columnNames = data[1]; // Second array contains column names

    // Iterate over each column
    for (let i = 0; i < columnNames.length; i++) {
      // Check if all values in the column are empty
      const allEmpty = data.slice(2).every((row) => !row[i].trim());
      // If all values are empty, remove the column
      if (allEmpty) {
        // Remove the i-th element from each row
        for (let j = 2; j < data.length; j++) {
          data[j].splice(i, 1);
        }
        // Remove the i-th column name
        columnNames.splice(i, 1);
        // Decrement i since we removed a column
        i--;
      }
    }
    console.log(data);
    return data;
  };

  const parseAndRenderTable = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    const tables = tempDiv.querySelectorAll("table");

    const renderedTables = Array.from(tables).map((table, index) => {
      const filteredTable = renderFilteredTable(table);
      return filteredTable.outerHTML;
    });

    return renderedTables.join("");
  };

  const renderFilteredTable = (table) => {
    const columnIndexesToRemove = getEmptyColumnIndexes(table);
    removeColumns(table, columnIndexesToRemove);

    return table;
  };

  const getEmptyColumnIndexes = (table) => {
    const rows = Array.from(table.querySelectorAll("tr"));
    const columnIndexesToRemove = new Set();

    rows.forEach((row) => {
      Array.from(row.querySelectorAll("td, th")).forEach((cell, index) => {
        if (!cell.textContent.trim() && !columnIndexesToRemove.has(index)) {
          columnIndexesToRemove.add(index);
        }
      });
    });

    return Array.from(columnIndexesToRemove);
  };

  const removeColumns = (table, columnIndexesToRemove) => {
    const rows = Array.from(table.querySelectorAll("tr"));

    rows.forEach((row) => {
      Array.from(row.querySelectorAll("td, th")).forEach((cell, index) => {
        if (columnIndexesToRemove.includes(index)) {
          cell.remove();
        }
      });
    });
  };

  return (
    <>
      <div className="m-5 pb-5">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <Spinner className="package-spinner" />
          </div>
        ) : (
          <>
            {dayClosureDetails && (
              <div
                className="dayclosure__table"
                dangerouslySetInnerHTML={{
                  __html: dayClosureDetails,
                }}
              />
            )}
          </>
        )}
        <div style={{ display: "flex", gap: "1rem" }}>
          <button
            className="header_menu__item"
            style={{ marginTop: "1rem", backgroundColor: "red" }}
            onClick={() => history.goBack(-1)}
          >
            Back
          </button>
          {dayClosureDetails && (
            <button
              className="header_menu__item mt-3"
              style={{ width: "200px" }}
              onClick={downloadReport}
            >
              {loading ? (
                <div class="spinner-border text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : null}
              Download Report
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default DayClosureHistoryDetails;
