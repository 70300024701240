import React, { useEffect, useMemo, useState } from "react";
import { IoHandLeft, IoSearchSharp, IoClose } from "react-icons/io5";
import { GrClose } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  Offcanvas,
  Row,
  Input,
  Form,
  Modal,
  ModalBody,
  Container,
  Spinner,
} from "reactstrap";
import logo from "../assets/images/pos-logo.jpg";
import { useDebouncedCallback } from "use-debounce";
import { useSelector } from "react-redux";
import {
  searchPackages,
  shiftSession,
  getShiftData,
  getSessionActiveStatus,
} from "../store/actions/discoverActions";
import OtpInput from "react-otp-input";
import { BsFillShieldLockFill } from "react-icons/bs";
import { useCookies } from "react-cookie";
import { logout, relogin, getUserProfile } from "../store/actions/authActions";
import ClearCartModal from "./ClearCartModal";
import RedeemOrderModal from "./RedeemOrderModal";
import ShiftModal from "./ShiftModal";
import { notifyError, notifySuccess } from "../utils/notifyToast";
import { useHotkeys } from "react-hotkeys-hook";

function Header() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isClearOpen, setClearOpen] = useState(false);
  const [isRedeemOpen, setRedeemOpen] = useState(false);
  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focusPin, setFocusPin] = useState(Math.random());
  const [pin, setPin] = useState("");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies(["cart"]);

  const isHomepage = useMemo(
    () => location.pathname === "/pos/DiscoverPoS",
    [location.pathname]
  );

  const toggleClearCart = () => [setClearOpen(!isClearOpen)];
  const toggleRedeemOpen = () => [setRedeemOpen(!isRedeemOpen)];
  const { shiftData, token, filterTerm, userDetails } = useSelector(
    ({ discover, authUser }) => ({
      shiftData: discover.shiftData,
      token: authUser.uid,
      filterTerm: discover.filterTerm,
      userDetails: authUser.userDetails,
    })
  );
  const [openShift, setOpenShift] = useState(false);
  const collapseToggle = () => {
    setCollapseOpen(!collapseOpen);
  };

  useEffect(() => {
    if (token && !userDetails.id) {
      dispatch(getUserProfile(+97100000000, token, () => {}));
    }
  }, [token, userDetails]);

  const reportingJSON = [
    {
      title: "My Order History",
      url: "/history",
    },
    {
      title: "Day Bookings",
      url: "/day-booking",
    },
    {
      title: "Redeems - Report",
      url: "/day-redeem",
    },
  ];

  const generalJSON = [
    {
      title: "Redeem",
      url: "",
    },
    {
      title: "Search User",
      url: "/find",
    },
  ];

  const myAccountJSON = [
    {
      title: localStorage.getItem("shift") ? "Shift Status" : "Start Shift",
      url: "",
    },
    {
      title: "Logout",
      url: "",
    },
  ];

  const dayClosureJSON = [
    {
      title: "Day Closure",
      url: "/day-closure",
    },
    {
      title: "Closure Reports",
      url: "/day-closure-history",
    },
  ];

  useEffect(() => {
    setSearchTerm(filterTerm);
  }, [filterTerm]);

  const search = useDebouncedCallback((_searchTerm) => {
    dispatch(searchPackages(_searchTerm));
  }, 800);

  useEffect(() => {
    if (localStorage.getItem("showForcePINInput") === "1") {
      setPinModalOpen(true);
      setFocusPin(Math.random());
    }
    dispatch(searchPackages(""));
    // checks only for session validation, if any err user will be forced to logout
    const sessionStatus = () => {
      getSessionActiveStatus(
        token,
        (response) => console.log(response),
        (err) => {
          console.log("validateActiveSession error", err);
          if (
            window.name !== "Payment - Discover POS" &&
            err.response?.status === 403
          ) {
            alert("validateActiveSession - Forcing POS User to Logout.");
            setTimeout(() => {
              onLogout();
            }, 2000);
          }
        }
      );
    };
    sessionStatus();
    const interval = setInterval(() => {
      sessionStatus();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    search(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  const useLogout = (startTime) => {
    const [timer, setTimer] = useState(startTime);
    useEffect(() => {
      const myInterval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        }
      }, 1000);
      const resetTimeout = () => {
        setTimer(startTime);
      };
      if (!pinModalOpen) {
        var events = [
          "load",
          "mousemove",
          "mousedown",
          "click",
          "scroll",
          "keypress",
        ];
      } else {
        var events = [];
      }
      for (let i in events) {
        window.addEventListener(events[i], resetTimeout);
      }
      return () => {
        clearInterval(myInterval);
        for (let i in events) {
          window.removeEventListener(events[i], resetTimeout);
        }
      };
    });
    return timer;
  };

  const timer = useLogout(300);

  useEffect(() => {
    if (!timer) {
      localStorage.setItem("showForcePINInput", "1");
      setPinModalOpen(true);
      setFocusPin(Math.random());
    }
  }, [timer]);

  const onLogout = () => {
    setCookies("cart", { packages: [], coupon: "" }, { path: "/" });
    document.cookie =
      "authToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    document.cookie =
      "userDetails=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    localStorage.removeItem("shift");
    localStorage.removeItem("showForcePINInput");
    // localStorage.removeItem("authToken");
    dispatch(logout());
    // history.replace("/");
  };

  const shiftReport = async () => {
    await dispatch(
      getShiftData(token, "checkForErr", "active_shift_status_simple", (res) =>
        console.log(res)
      )
    );
    history.replace("/shift-session-report");
  };

  useEffect(() => {
    if (pin.length === 4) {
      handleLoginAgain();
    }
  }, [pin]);

  const handleLoginAgain = (e) => {
    e && e.preventDefault();
    relogin(
      setLoading,
      { username: cookies.username, pin: pin },
      token,
      (response) => {
        console.log(response);
        localStorage.setItem("showForcePINInput", "0");
        setPinModalOpen(false);
        setPin("");
        notifySuccess(response.message);
      },
      (err) => {
        setPin("");
        setFocusPin(Math.random());
        notifyError("Invalid Pin");
      }
    );
  };

  useHotkeys("P", () => history.push("/"));

  return (
    <>
      <div className="custom__navbar text-white">
        <div className="custom__width d-flex align-items-center justify-space-between">
          {isHomepage ? (
            <div className="d-flex ps-4 align-items-center">
              <IoSearchSharp
                style={{ height: "25px", width: "25px", cursor: "pointer" }}
                onClick={() => setShowInput(!showInput)}
              />
              {showInput && (
                <Input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: 160, height: 40 }}
                  placeholder="Search packages..."
                  className="search__field"
                />
              )}
            </div>
          ) : null}
          <h2 className="menu m-0 ms-3" style={{ paddingRight: "1rem" }}>
            {cookies.username}
            {localStorage.getItem("shift") && shiftData?.shift_session && (
              <h2 className="m-0" style={{ fontSize: 14, paddingTop: "5px" }}>
                Shift : {shiftData.shift_session.shift_length}
              </h2>
            )}
          </h2>
          <ul
            className="menu__header d-flex me-2 gap-3"
            style={{ paddingRight: "1rem" }}
          >
            {!localStorage.getItem("shift") && (
              <li>
                <NavLink to="#">
                  <button
                    className="header_menu__item"
                    onClick={() => setOpenShift(true)}
                  >
                    Start Shift
                  </button>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/">
                <button className="header_menu__item">Products</button>
              </NavLink>
            </li>
            <li>
              <button onClick={toggleRedeemOpen} className="header_menu__item">
                Redeem
              </button>
            </li>
            <li>
              <NavLink to="/find">
                <button className="header_menu__item">Search User</button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/history">
                <button className="header_menu__item">My Orders</button>
              </NavLink>
            </li>
            <li>
              <button className="header_menu__item" onClick={toggleClearCart}>
                New Cart
              </button>
            </li>
          </ul>

          {/* <p className="menu m-0 ms-5 page__title">
            <NavLink to="/">Discover Mleiha</NavLink>
          </p> */}
          <i
            className="fas fa-stream fa-2x pointer"
            onClick={collapseToggle}
          ></i>
        </div>
      </div>
      <ClearCartModal isOpen={isClearOpen} toggleOpen={toggleClearCart} />
      <ShiftModal
        isOpen={openShift}
        toggleOpen={() => setOpenShift(false)}
        setCookies={setCookies}
      />
      <RedeemOrderModal isOpen={isRedeemOpen} toggleOpen={toggleRedeemOpen} />
      <Modal id="pin" centered isOpen={pinModalOpen} size="md">
        <ModalBody className="px-5 py-4">
          <span className="d-flex justify-content-center mb-4">
            {/* <i class="fa fa-lock" aria-hidden="true"></i> */}
            <BsFillShieldLockFill style={{ color: "black", fontSize: 28 }} />
          </span>
          <h4 className="mb-4 d-flex justify-content-center">
            Continue Session
          </h4>
          <Form onSubmit={handleLoginAgain}>
            <span className="d-flex justify-content-center mb-4">
              <OtpInput
                key={focusPin}
                separator={
                  <span>
                    <strong>.</strong>
                  </span>
                }
                value={pin}
                isInputSecure
                shouldAutoFocus={Boolean(focusPin)}
                onChange={(value) => setPin(value)}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 1rem",
                  fontSize: "2rem",
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.3)",
                }}
              />
            </span>
            <div className="d-flex gap-2">
              <button
                type="button"
                className="header_menu__item mt-3 w-50"
                onClick={onLogout}
              >
                Logout
              </button>
              <button
                type="submit"
                className="header_menu__item mt-3 w-50"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : null}
                Submit
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Offcanvas
        id="sidebar"
        isOpen={collapseOpen}
        toggle={collapseToggle}
        className="sidebar__collapse text-black"
        direction="top"
      >
        <div className="sidebar__collapse-child">
          <Nav vertical className="mt-3 custom__nav">
            <div className="d-flex justify-content-between">
              <div className="text-center image__container">
                <img src={logo} alt="" className="img-fluid logo d-none" />
              </div>
              <div>
                <IoClose
                  style={{ color: "black", cursor: "pointer", fontSize: 38 }}
                  onClick={collapseToggle}
                />
              </div>
            </div>
            {/* <NavItem className={`mt-5 ${activeNav == "packages" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="far fa-star"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("packages")}>
										Packages
									</NavLink>
								</Col>
							</Row>
						</NavItem>
						<NavItem className={`${activeNav == "verifyorder" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="fa fa-mouse-pointer"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("verifyorder")}>
										Verify Order
									</NavLink>
								</Col>
							</Row>
						</NavItem>
						<NavItem className={`${activeNav == "productivity" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="fa fa-briefcase"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("productivity")}>
										My Order History
									</NavLink>
								</Col>
							</Row>
						</NavItem>
						<NavItem className={`${activeNav == "privacy" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="fa fa-users"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("privacy")}>
										Customer Profile
									</NavLink>
								</Col>
							</Row>
						</NavItem> */}

            {/* <NavItem
              className={`${
                activeNav == "accessible" && "custom__nav__active"
              }`}
            >
              <Row>
                <Col xs="2">
                  <i className="far fa-comment-alt"></i>
                </Col>
                <Col>
                  <NavLink
                    to=""
                    onClick={() =>
                      window.open(
                        "https://docs.google.com/forms/d/e/1FAIpQLSc67FGD0ijxzqA9DKBDgCDMdHt10PHZ9LcFRPN6hmyxtZgIVw/viewform",
                        "_blank",
                        "toolbar,scrollbars,resizable,top=500,left=500,width=1000,height=1000"
                      )
                    }
                  >
                    Issues / Suggestions
                  </NavLink>
                </Col>
              </Row>
            </NavItem>
            <NavItem
              onClick={shiftReport}
              className={`${activeNav == "getstart" && "custom__nav__active"}`}
            >
              <Row>
                <Col xs="2">
                  <i className="fa fa-lock"></i>
                </Col>
                <Col>
                  <NavLink to="#">Shift Report</NavLink>
                </Col>
              </Row>
            </NavItem>
            <NavItem
              onClick={onLogout}
              className={`${activeNav == "getstart" && "custom__nav__active"}`}
            >
              <Row>
                <Col xs="2">
                  <i className="fa fa-lock"></i>
                </Col>
                <Col>
                  <NavLink to="#">Logout</NavLink>
                </Col>
              </Row>
            </NavItem>
            <NavItem
              onClick={() => history.replace("/day-closure")}
              className={`${activeNav == "getstart" && "custom__nav__active"}`}
            >
              <Row>
                <Col xs="2">
                  <i className="fa fa-lock"></i>
                </Col>
                <Col>
                  <NavLink to="#">Day Closure</NavLink>
                </Col>
              </Row>
            </NavItem> */}
            <div className="sidebar__container mt-4">
              <div className="sidebar__row">
                <h4>Reporting</h4>
                <Row className="mt-2 mb-5">
                  <ul className="sidebar__header m-0 p-0 d-flex ms-auto me-2 gap-3">
                    {reportingJSON.map((item, index) => (
                      <NavLink to={item.url} className="sidebar__link">
                        <Col
                          key={index}
                          lg="4"
                          md="4"
                          xl="6"
                          className="sidebar__item m-3"
                        >
                          {item.title}
                        </Col>
                      </NavLink>
                    ))}
                  </ul>
                </Row>
              </div>
              <div className="sidebar__row">
                <h4>General</h4>
                <Row className="mt-2 mb-5">
                  <ul className="sidebar__header m-0 p-0 d-flex ms-auto me-2 gap-3">
                    {generalJSON.map((item, index) => (
                      <NavLink
                        to={item.url || "#"}
                        onClick={() => {
                          if (!item.url) {
                            toggleRedeemOpen();
                          }
                        }}
                        className="sidebar__link"
                      >
                        <Col
                          key={index}
                          lg="4"
                          md="4"
                          xl="6"
                          className="sidebar__item m-3"
                        >
                          {item.title}
                        </Col>
                      </NavLink>
                    ))}
                  </ul>
                </Row>
              </div>
            </div>
            <div className="sidebar__container">
              <div className="sidebar__row">
                <h4>My Account</h4>
                <Row className="mt-2 mb-5">
                  <ul className="sidebar__header m-0 p-0 d-flex ms-auto me-2 gap-3">
                    {myAccountJSON.map((item, index) => (
                      <NavLink
                        to={item.url || "#"}
                        onClick={() => {
                          if (!item.url) {
                            if (item.title === "Logout") {
                              onLogout();
                            } else if (item.title === "Start Shift") {
                              setOpenShift(true);
                            } else {
                              shiftReport();
                            }
                          }
                        }}
                        className="sidebar__link"
                      >
                        <Col
                          key={index}
                          lg="4"
                          md="4"
                          xl="6"
                          className="sidebar__item m-3"
                        >
                          {item.title}
                        </Col>
                      </NavLink>
                    ))}
                  </ul>
                </Row>
              </div>
              <div className="sidebar__row">
                <h4>Day Closure</h4>
                <Row className="mt-2 mb-5">
                  <ul className="sidebar__header m-0 p-0 d-flex ms-auto me-2 gap-3">
                    {dayClosureJSON.map((item, index) => (
                      <NavLink to={item.url} className="sidebar__link">
                        <Col
                          key={index}
                          lg="4"
                          md="4"
                          xl="6"
                          className="sidebar__item m-3"
                        >
                          {item.title}
                        </Col>
                      </NavLink>
                    ))}
                  </ul>
                </Row>
              </div>
            </div>
          </Nav>
        </div>
      </Offcanvas>
    </>
  );
}

export default Header;
