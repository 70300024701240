import React from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";

const purchaseOrderDataModel = [
  {
    name: "Package Name",
    selector: (row) => row.package_name,
  },
  {
    name: "Attendee(s)",
    selector: (row) => Number(row.child_qty) + Number(row.adult_qty),
    center: true,
  },
  {
    name: "Date",
    cell: (row) =>
      row.date !== "0000-00-00" && moment(row.date).format("DD MMM, YYYY"),
    center: true,
  },
  {
    name: "Time",
    selector: (row) => row.order_timeslot,
    center: true,
  },
  {
    name: "Addon(s)",
    cell: (row) =>
      row.sub_package.length ? row.sub_package.split(",").length : "No",
    center: true,
  },
  {
    name: "Total Amount",
    cell: (row) => <div>AED {Number(row.total) || 0}</div>,
    center: true,
  },
];

const successPurchaseOrderDataModel = () => [
  ...purchaseOrderDataModel,
  {
    name: "",
    cell: (row) => (
      <NavLink to={`/order-details/${row.order_number}`}>
        <button className="header_menu__item mt-2 mb-2">
          <span style={{ fontSize: 12 }}>
            {row.redeem ? "Details" : "Redeem"}
          </span>
        </button>
      </NavLink>
    ),
  },
];

const PaymentOrderData = ({ orderData }) => {
  // orderData = orderData.filter((order) =>
  //   order.items?.hasOwnProperty("packages")
  // );
  return (
    <>
      {!!orderData.length && orderData[0] && (
        <div className="order__data mt-5">
          <div className="mt-3 mb-3 w-100 d-flex justify-content-between align-items-center">
            <h5>Order(s)</h5>
          </div>
          <DataTable
            columns={
              orderData.length
                ? successPurchaseOrderDataModel()
                : purchaseOrderDataModel
            }
            data={orderData}
          />
        </div>
      )}
    </>
  );
};

export default PaymentOrderData;
