import Login from "../auth/Login";
import CloseWindow from "../views/closeWindow";
import BookingDetails from "../views/BookingDetails";
import DiscoverMleiha from "../views/DiscoverMleiha";
import FindUser from "../views/FindUser";
import ProductDetails from "../views/ProductDetails";
import PurchaseHistory from "../views/PurchaseHistory";
import RedeemBooking from "../views/RedeemBooking";
import UserDetails from "../views/UserDetails";
import ShiftSummary from "../views/ShiftSummary";
import DayClosure from "../views/DayClosure";
import DayBooking from "../views/DayBooking";
import DayRedeem from "../views/DayRedeem";
import PrintDetails from "../views/PrintDetails";
import DayClosureHistory from "../views/DayClosureHistory";
import DayClosureHistoryDetails from "../views/DayClosureHistoryDetails";

let routes = [
  {
    path: "/auth/login",
    component: Login,
    layout: "auth",
  },
  {
    path: "/history",
    component: PurchaseHistory,
    layout: "pos",
  },
  {
    path: "/find",
    component: FindUser,
    layout: "pos",
  },
  {
    path: "/shift-session-report",
    component: ShiftSummary,
    layout: "pos",
    exact: true,
  },
  {
    path: "/day-closure",
    component: DayClosure,
    layout: "pos",
    exact: true,
  },
  {
    path: "/day-closure-history",
    component: DayClosureHistory,
    layout: "pos",
    exact: true,
  },
  {
    path: "/day-closure-history/:day_closure_id",
    component: DayClosureHistoryDetails,
    layout: "pos",
    exact: true,
  },
  {
    path: "/pos/DiscoverPoS",
    component: DiscoverMleiha,
    layout: "pos",
    exact: true,
  },
  {
    path: "/p/:package_slug",
    component: ProductDetails,
    layout: "pos",
  },
  {
    path: "/order-details/:booking_number",
    component: RedeemBooking,
    layout: "pos",
  },
  {
    path: "/user-details/:user_id",
    component: UserDetails,
    layout: "pos",
  },
  {
    path: "/purchase-details/:purchase_number",
    component: BookingDetails,
    layout: "pos",
  },
  {
    path: "/printReceipt/:purchase_number",
    component: PrintDetails,
    layout: "auth",
  },
  {
    path: "/closewindow",
    component: CloseWindow,
    layout: "pos",
  },
  {
    path: "/day-booking",
    component: DayBooking,
    layout: "pos",
  },
  {
    path: "/day-redeem",
    component: DayRedeem,
    layout: "pos",
  },
];
export default routes;
