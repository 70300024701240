import React, { useState, useMemo, useEffect } from "react";
import cx from "classnames";
import Users from "./users";
import Calendar from "./calendar";
import FluentFood from "./fluentFood";
import Transport from "./transport";
import SubPackages from "./subPackages";
import TimeSlot from "./timeSlot";
import arrow from "../../images/icons/Arrow-Right.svg";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { Input, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { is } from "date-fns/locale";
import { useCookies } from "react-cookie";

function ProductDetailSteps(props) {
  const [step, setStep] = useState(1);
  const [sub_name, setSubName] = useState("user");

  console.log("propssss: ", props);

  const {
    setNumPerson,
    packageDetails,
    nextPage,
    timeSlots,
    setShowTimeSlot,
    showTimeSlot,
    timeSlotValue,
    handleDateChange,
    minDate,
    maxDate,
    selectedDate,
    isBooking,
    bookYourPackage,
    gotoSlide,
    ticketsValue,
    realDate,
    packagePrice,
    mealPrice,
    addChild,
    addPerson,
    time_slot,
    selectedTimeSlot,
    changeTimeSlot,
    addSubChild,
    addSubAdult,
    subPackages,
    addMealPerson,
    meals,
    isLoading,
    transports,
    transportPrice,
    showDetails,
    isShowDetails,
    enable_date_picker,
    enable_time_picker,
    enable_transport,
    enable_meal,
    enable_sub_packages,
    transport_id,
    isOneWay,
    checkTransport,
    setTransportID,
    isShowCoupon,
    realCoupon,
    total,
    disabled_dates,
    disabled_weeks,
    changePriceValue,
    customAdultPrice,
    customChildPrice,
    paymentMethod,
    setPaymentMethod,
    notes,
    setNotes,
  } = props;

  const storeData = useSelector((store) => store);
  const [cookies] = useCookies(["cart"]);
  const [stepList, setStepList] = useState([]);
  const [isSticky, showSticky] = useState(false);
  const [clientHeight, setClientHeight] = useState(
    document.documentElement.clientHeight
  );
  const history = useHistory();
  const [isCouponCode, setCouponCode] = useState(true);
  useEffect(() => {
    if (window.location.href.indexOf("experience") > -1) {
      setCouponCode(false);
    }
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (
        currentScrollY + clientHeight >
        document.documentElement.scrollHeight * 0.85
      ) {
        showSticky(true);
      } else {
        showSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [clientHeight]);

  useEffect(() => {
    setClientHeight(document.documentElement.clientHeight);
    let index = 2;
    setStepList([]);
    let temp = [];
    temp.push({
      id: 1,
      value: "user",
      icon: <i class="far fa-user booking-flow-icon"></i>,
      detail: total - mealPrice - packagePrice - transportPrice,
    });
    if (enable_date_picker) {
      temp.push({
        id: index,
        value: "date",
        icon: <i class="far fa-calendar-alt booking-flow-icon"></i>,
        detail: realDate,
      });
      index++;
    }
    if (enable_time_picker) {
      temp.push({
        id: index,
        value: "time",
        icon: <i class="far fa-clock  booking-flow-icon"></i>,
        detail: time_slot,
      });
      index++;
    }
    if (enable_sub_packages) {
      temp.push({
        id: index,
        value: "sub_packages",
        icon: <i class="far fa-plus-square   booking-flow-icon"></i>,
        detail: packagePrice,
      });
      index++;
    }
    if (enable_transport) {
      temp.push({
        id: index,
        value: "transports",
        icon: <i class="fas fa-car booking-flow-icon"></i>,
        detail: transportPrice,
      });
      index++;
    }
    if (enable_meal) {
      temp.push({
        id: index,
        value: "meals",
        icon: <i class="fas fa-utensils booking-flow-icon"></i>,
        detail: mealPrice,
      });
    }
    setStepList(temp);
    console.log("setstep=", stepList);
  }, [
    enable_date_picker,
    enable_time_picker,
    packageDetails,
    total,
    realDate,
    packagePrice,
    mealPrice,
    time_slot,
    timeSlotValue,
    transportPrice,
    enable_sub_packages,
    enable_meal,
    enable_transport,
  ]);

  const handleStep = () => {
    let result = nextPage();
    if (result == false) {
      return;
    }
    setStep((previous) => (previous === 6 ? 1 : previous + 1));
    setSubName(stepList[step].value);
  };

  function goNextStep(index, value) {
    let result = gotoSlide(value);
    if (result == false) {
      return;
    }
    setStep(index + 1);
    setSubName(value);
  }

  const userHasData = useMemo(() => {
    return (
      storeData.authUser.userDetails.name?.length ||
      storeData.authUser.userDetails.mobile?.length
    );
  }, [storeData.authUser.userDetails]);

  const addToCart = () => {
    if (nextPage()) isShowDetails(true);
  };

  const quickCheckout = async () => {
    if (nextPage()) {
      if (localStorage.getItem("shift") && userHasData) {
        await bookYourPackage(true);
        setStep(1);
        setSubName("user");
      }
    }
  };

  useHotkeys("enter", () => {
    const isModalOpen = document.body.classList.contains("modal-open");
    const isPinModalOpen = document.getElementById("pin");
    const isSidebarOpen = document.getElementById("sidebar");
    if (showDetails) {
      bookYourPackage();
    } else if (!isModalOpen && !isPinModalOpen && !isSidebarOpen) {
      isBooking ? addToCart() : handleStep();
    }
  });

  return (
    <>
      <div className="stepsTab">
        <div className="wrapper">
          {stepList?.map(({ id, icon, value, detail }, index) => (
            <div
              key={id + index}
              className={cx("item", "item-" + stepList.length)}
            >
              <div
                className={cx("btnWrapper", {
                  active: id <= step,
                  activeFull: id === step,
                })}
              >
                <button
                  className={
                    cx({ active: id <= step }) +
                    (storeData.authUser.uid ? "" : "disable-button")
                  }
                  onClick={() => goNextStep(index, value)}
                >
                  {icon}
                </button>
              </div>
              {value == "time" || value == "date" ? (
                <p>{detail}</p>
              ) : (
                <p>
                  {detail} <small>AED</small>
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      {packageDetails.disabled === "1" || packageDetails.enable === "0" ? (
        <div
          className="render-content"
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          Sorry, bookings are disabled for this package
        </div>
      ) : (
        <div className="render-content">
          {sub_name == "user" && (
            <Users
              addChild={addChild}
              addPerson={addPerson}
              adult_price={packageDetails?.price}
              child_price={packageDetails?.child_price}
              setNumPerson={setNumPerson}
              changePriceValue={changePriceValue}
              customAdultPrice={customAdultPrice}
              customChildPrice={customChildPrice}
              packageDetails_id={packageDetails.id}
            />
          )}
          {sub_name == "date" && (
            <Calendar
              handleDateChange={handleDateChange}
              disabled_dates={disabled_dates}
              minDate={minDate}
              maxDate={maxDate}
              selectedDate={selectedDate}
              disabled_weeks={disabled_weeks}
            />
          )}
          {sub_name == "time" && (
            <TimeSlot
              showTimeSlot={showTimeSlot}
              timeSlots={timeSlots}
              setShowTimeSlot={setShowTimeSlot}
              timeSlotValue={timeSlotValue}
              changeTimeSlot={changeTimeSlot}
              selectedTimeSlot={selectedTimeSlot}
            />
          )}
          {sub_name == "sub_packages" && (
            <SubPackages
              addSubAdult={addSubAdult}
              addSubChild={addSubChild}
              subPackages={subPackages}
            />
          )}
          {sub_name == "meals" && (
            <FluentFood meals={meals} addMealPerson={addMealPerson} />
          )}
          {sub_name == "transports" && (
            <Transport
              transports={transports}
              transport_id={transport_id}
              checkTransport={checkTransport}
              isOneWay={isOneWay}
              setTransportID={setTransportID}
            />
          )}
          {packageDetails.pos_notes_required === "1" && (
            <Input
              style={{ marginBottom: "3rem", marginTop: "2rem" }}
              type="input"
              placeholder="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          )}
          {storeData.authUser.uid ? (
            <>
              {isBooking ? (
                <div className={isSticky ? "book sticky row" : "book row"}>
                  {realCoupon && (
                    <div
                      className="row"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        backgroundColor: "#fff",
                      }}
                    >
                      <div className="col-6">Coupon: {realCoupon}</div>
                      <div className="col-6">
                        Discount:{" "}
                        {(
                          ticketsValue +
                          mealPrice +
                          packagePrice +
                          transportPrice -
                          total
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}

                  <div className="d-flex">
                    <div className="total">
                      <p>
                        Total: {total} <small>AED</small>
                      </p>
                      {/* {isCouponCode && (
                    <Button
                      className="btn-coupon"
                      style={{ fontSize: "80%" }}
                      onClick={() => {
                        isShowCoupon(true);
                      }}
                    >
                      Enter Promo Code
                    </Button>
                  )} */}
                    </div>
                    {isLoading ? (
                      <button className="btn next-btn btn-primary">
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Add to Cart
                      </button>
                    ) : (
                      <button
                        className="btn next-btn btn-primary"
                        onClick={addToCart}
                      >
                        Add to Cart
                      </button>
                    )}
                    {packageDetails.quick_checkout === "1" && (
                      <>
                        {isLoading ? (
                          <button className="btn next-btn btn-primary">
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Quick Checkout
                          </button>
                        ) : (
                          <button
                            className="btn next-btn btn-primary"
                            onClick={quickCheckout}
                            disabled={cookies.cart?.packages?.length}
                          >
                            Quick Checkout
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  {packageDetails.quick_checkout === "1" && (
                    <div
                      className="payment-method"
                      style={{ marginTop: "1rem", justifyContent: "flex-end" }}
                    >
                      {[
                        { title: "CARD", value: "card_manual" },
                        { title: "CASH", value: "cash" },
                      ].map((payment) => (
                        <label className="payment-card">
                          <input
                            name="plan"
                            className="radio"
                            type="radio"
                            value={payment.value}
                            checked={paymentMethod === payment.value}
                            onClick={(e) => setPaymentMethod(e.target.value)}
                          />

                          <span className="payment-details">
                            <span className="payment-type">
                              {payment.title}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="payment-icon"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className={isSticky ? "book sticky row" : "book row"}>
                  {realCoupon && (
                    <div
                      className="row"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        backgroundColor: "#fff",
                      }}
                    >
                      <div className="col-6">Coupon: {realCoupon}</div>
                      <div className="col-6">
                        Discount:{" "}
                        {(
                          ticketsValue +
                          mealPrice +
                          packagePrice +
                          transportPrice -
                          total
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}
                  <div className="total">
                    <p>
                      Total: {total} <small>AED</small>
                    </p>
                    {/* {isCouponCode && (
                    <Button
                      className="btn-coupon"
                      style={{ fontSize: "80%" }}
                      onClick={() => {
                        isShowCoupon(true);
                      }}
                    >
                      Enter Promo Code
                    </Button>
                  )} */}
                  </div>
                  {isLoading ? (
                    <button className="btn next-btn btn-primary">
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Next
                      <img src={arrow} alt="" />
                    </button>
                  ) : (
                    <button
                      className="btn next-btn btn-primary"
                      onClick={() => {
                        handleStep();
                      }}
                    >
                      Next
                      <img src={arrow} alt="" />
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <div
              className={isSticky ? "book sticky row mx-0" : "book row mx-0"}
            >
              <div className="total"></div>
              <div className="text-right w-100">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  SignUp
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ProductDetailSteps;
