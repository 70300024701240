import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPurchaseDetails } from "../store/actions/discoverActions";
import { useSelector } from "react-redux";
import PaymentUserData from "../components/PaymentUserData";
import PaymentPurchaseData from "../components/PaymentPurchaseData";
import PaymentOrderData from "../components/PaymentOrderData";
import PaymentTransactionData from "../components/PaymentTransactionData";
import PaymentPromotionData from "../components/PaymentPromotionData";
import Comments from "../components/Comments";
import { notifyError } from "../utils/notifyToast";

const BookingDetails = ({
  match: {
    params: { purchase_number = "" },
  },
}) => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [orderAlert, setOrderAlert] = useState("");
  const [notes, setNotes] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authUser.uid);

  useEffect(() => {
    if (!token?.length) return;
    if (!paymentDetails) return;

    let interval;
    if (Number(paymentDetails.purchase_data?.status || "0") !== 1)
      interval = setInterval(() => {
        if (
          Number(paymentDetails.purchase_data?.status || "0") === 1 ||
          paymentDetails === "failed"
        )
          return;
        dispatch(
          getPurchaseDetails(
            purchase_number,
            token,
            (deets) => {
              if (!deets.success) {
                notifyError(deets.msg || "Failed to load data.");
                setOrderAlert(deets.msg);
                return;
              }
              setPaymentDetails(deets.data);
            },
            (err) => {
              setPaymentDetails("failed");
              notifyError("Failed to fetch payment data. Reload.", {
                position: "bottom-center",
              });
            }
          )
        );
      }, 3 * 1000);

    if (Number(paymentDetails.purchase_data?.status || "0") === 1)
      clearInterval(interval);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [purchase_number, paymentDetails, token]);

  useEffect(() => {
    handlePurchaseDetails();
  }, []);

  const handlePurchaseDetails = () => {
    if (!token?.length) return;

    dispatch(
      getPurchaseDetails(
        purchase_number,
        token,
        (deets) => {
          console.log(deets);
          if (!deets.success) {
            notifyError(deets.msg || "Failed to load data.");
            setOrderAlert(deets.msg);
            return;
          }
          setNotes(
            deets.data.purchase_data.internal_notes
              ? JSON.parse(deets.data.purchase_data.internal_notes)
              : []
          );
          setPaymentDetails(deets.data);
        },
        (err) => {
          setPaymentDetails("failed");
          notifyError("Failed to fetch payment data. Reload.", {
            position: "bottom-center",
          });
        }
      )
    );
  };

  return (
    <div>
      {!paymentDetails && (
        <h6
          style={{
            textAlign: "center",
            marginTop: "3rem",
            fontSize: "1.5rem",
          }}
        >
          {orderAlert || "Loading data..."}
        </h6>
      )}
      {paymentDetails && paymentDetails === "failed" ? (
        <h4 style={{ textAlign: "center", marginTop: "3rem" }}>
          Failed to load data.
        </h4>
      ) : null}
      {paymentDetails && paymentDetails !== "failed" && (
        <div className="payment__container">
          <PaymentUserData
            userData={paymentDetails.user_data}
            status={
              paymentDetails.purchase_data
                ? paymentDetails.purchase_data.status
                : "failed"
            }
            orderData={paymentDetails.order_data}
            purchaseResponse={{
              purchase_number: paymentDetails.purchase_data.purchase_number,
            }}
          />
          <PaymentPromotionData
            coupon={
              paymentDetails.purchase_data
                ? paymentDetails.purchase_data.items?.coupon_id
                : ""
            }
            orderData={paymentDetails.order_data}
          />
          <PaymentPurchaseData
            purchaseData={
              paymentDetails.purchase_data ? [paymentDetails.purchase_data] : []
            }
          />
          <PaymentOrderData
            orderData={paymentDetails.order_data}
            status={
              paymentDetails.purchase_data
                ? paymentDetails.purchase_data.status
                : "failed"
            }
          />
          <PaymentTransactionData
            transactionData={
              paymentDetails.transactions_data.length
                ? paymentDetails.transactions_data
                : []
            }
          />
          <div className="mt-4">
            <h5>Internal Notes(s)</h5>
            {notes.map((note, i) => (
              <div style={{ marginLeft: "1rem" }}>{note.comment}</div>
            ))}
          </div>
          <Comments
            purchaseNumber={paymentDetails.purchase_data.purchase_number}
            handleDetails={handlePurchaseDetails}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(BookingDetails);
