import React, { useState, useCallback, useRef } from "react";
import {
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { getCountryCallingCode } from "react-phone-number-input";
import { getUserProfile } from "../store/actions/authActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import countryList from "react-select-country-list";
import { notifySuccess } from "../utils/notifyToast";
import { useHotkeys } from "react-hotkeys-hook";

function NewCustomerForm(props) {
  let { isOpen, toggleNewUserDetails } = props;
  const inputRef = useRef(null);
  const { token, profileFetchStatus } = useSelector(({ authUser }) => ({
    token: authUser.uid,
    profileFetchStatus: authUser.profileFetchStatus,
  }));

  const dispatch = useDispatch();

  const [localUserProfile, setLocalUserProfile] = useState({
    mobile: "",
  });
  const [countryCode, setCountryCode] = useState("AE");

  const updateUserLocalProfile = (field, value) => {
    setLocalUserProfile({ ...localUserProfile, [field]: value });
  };

  const onPhoneSubmit = () => {
    if (!localUserProfile.mobile.length) {
      alert("Please enter the phone number.");
      return;
    }
    if (
      localUserProfile.mobile.length < 9 ||
      localUserProfile.mobile.length > 10
    ) {
      alert("Please enter valid phone number.");
      return;
    }
    if (!countryCode.length) {
      alert("Please choose your country.");
      return;
    }
    const countryPhoneCode = getCountryCallingCode(countryCode);

    return dispatch(
      getUserProfile(
        `${countryPhoneCode}${localUserProfile.mobile}`,
        token,
        () => {
          notifySuccess("Profile added.");
          toggleNewUserDetails();
          // setCookies(
          //   "cart",
          //   {
          //     packages: [],
          //     coupon: null,
          //     coupon_id: "",
          //   },
          //   {
          //     path: "/",
          //   }
          // );
        }
      )
    );
  };

  const countryCodeAsync = useCallback((countryCode) => {
    try {
      return getCountryCallingCode(countryCode);
    } catch (err) {
      return "000";
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onPhoneSubmit();
    }
  };

  useHotkeys("enter", (event) => {
    if (
      document.activeElement !== inputRef.current &&
      !document.getElementById("pin") &&
      isOpen
    ) {
      event.preventDefault();
      onPhoneSubmit();
    }
  });

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={toggleNewUserDetails} size="lg">
        <ModalHeader toggle={toggleNewUserDetails}>
          New Customer Details
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onPhoneSubmit();
            }}
          >
            <Label className="mt-2">Phone Number</Label>
            <InputGroup>
              <InputGroupText>
                <Input
                  style={{ width: 275 }}
                  value={countryCode}
                  type="select"
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                >
                  {countryList()
                    .getData()
                    .map((item) => (
                      <option key={item.value} value={item.value}>
                        +{countryCodeAsync(item.value)} - {item.label}
                      </option>
                    ))}
                </Input>
              </InputGroupText>
              <Input
                ref={inputRef}
                placeholder="5678567890"
                value={localUserProfile.mobile}
                onChange={(e) => {
                  updateUserLocalProfile("mobile", e.target.value);
                }}
                type="tel"
                handleKeyPress={handleKeyPress}
                required
              />
            </InputGroup>
            <button
              type="submit"
              className="header_menu__item mt-3 w-100"
              // onClick={onPhoneSubmit}
            >
              {profileFetchStatus === "requested" ? (
                <div class="spinner-border text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : null}
              Submit
            </button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default NewCustomerForm;
