import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_PACKAGE } from "../store/types";
import {
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import {
  getPackages,
  getTags,
  selectTag,
  searchPackages,
  getShiftData,
} from "../store/actions/discoverActions";
import defaultImage from "../assets/images/gray.png";
import ShiftModal from "../components/ShiftModal";
import {
  ArrowLeft,
  ArrowRight,
} from "../components/PackageDetail/SvgComponentss";
import { NavLink, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useHotkeys } from "react-hotkeys-hook";

function DiscoverMleiha() {
  const dispatch = useDispatch();
  const [, setCookies] = useCookies("");
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openShift, setOpenShift] = useState(false);
  const [openShiftRunning, setOpenShiftRunning] = useState(false);
  const { shiftData, token } = useSelector(({ discover, authUser }) => ({
    shiftData: discover.shiftData,
    token: authUser.uid,
  }));
  const { packages, filterTerm, activeTag, tags } = useSelector(
    (state) => state.discover
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("showActiveSession")) {
      dispatch(
        getShiftData(
          token,
          "noErrCheck",
          "active_shift_status_simple",
          (res) => {
            console.log(res);
            if (res) {
              setOpenShiftRunning(true);
            } else {
              setOpenShift(true);
            }
          }
        )
      );
      localStorage.setItem("showActiveSession", "");
    }
  }, [localStorage.getItem("showActiveSession")]);

  useEffect(() => {
    if (!packages?.length) {
      dispatch(getPackages(token, setLoading));
      dispatch(getTags(token));
    }
    dispatch({
      type: FETCH_PACKAGE,
      payload: [],
    });
  }, [packages]);

  const history = useHistory();

  const tagFilteredPackages = useMemo(() => {
    if (!activeTag.length) return packages;
    if (activeTag === "favorite") {
      return packages.filter((_pack) => _pack.favorite);
    } else {
      return packages.filter((_pack) =>
        _pack.tags?.split(",").includes(activeTag)
      );
    }
  }, [activeTag, packages]);

  const filteredPackages = useMemo(() => {
    if (!filterTerm.length) return tagFilteredPackages;

    const regSearch = new RegExp(filterTerm, "i");
    return tagFilteredPackages.filter((_package) => {
      return _package.package_name.search(regSearch) >= 0;
    });
  }, [filterTerm, tagFilteredPackages]);

  const changeTag = (tagName) => {
    dispatch(selectTag(tagName));
    dispatch(searchPackages(""));
  };

  useHotkeys(
    "enter",
    !document.getElementById("pin") &&
      openShiftRunning &&
      setOpenShiftRunning(false)
  );

  return (
    <>
      <Container
        className="mt-5 mb-5 pb-5"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Row
          style={{
            gap: isMobile ? "2rem" : "2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {filteredPackages?.length > 0 &&
            filteredPackages
              .sort(
                (a, b) =>
                  moment(a.created_at?.split(" ")[0]) -
                  moment(b.created_at?.split(" ")[0])
              )
              .map((pack, index) => {
                const truncatedName =
                  pack.package_name.length > 60
                    ? pack.package_name.substring(0, 60) + "..."
                    : pack.package_name;

                return (
                  pack.package_name !== "" &&
                  // pack.hide === "0" &&
                  pack.enable === "1" &&
                  pack.checkout?.includes("POS") && (
                    <NavLink
                      to={`/p/${pack.url_slug}`}
                      className="package__link"
                      style={{ width: isMobile ? "250px" : "300px" }}
                    >
                      <Col key={index} className="package__item">
                        <LazyLoadImage
                          src={`${process.env.REACT_APP_API}cdn-cgi/image/width=500,height=200,quality=100/files/${pack.img}`}
                          style={{
                            borderRadius: "10px",
                            width: isMobile ? "250px" : "300px",
                            height: "200px",
                            filter: "brightness(70%)",
                          }}
                          onError={(e) => (e.target.src = defaultImage)}
                          effect="blur"
                        />
                        <h6>{truncatedName}</h6>
                      </Col>
                    </NavLink>
                  )
                );
              })}
        </Row>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <Spinner className="package-spinner" />
          </div>
        )}
      </Container>
      {!loading &&
        !filteredPackages.filter(
          (pack, index) =>
            pack.package_name !== "" &&
            // pack.hide === "0" &&
            pack.enable === "1" &&
            pack.checkout?.includes("POS")
        ).length && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 30,
              height: "50%",
            }}
          >
            No Products Found
          </div>
        )}
      <Container className="tag__footer">
        <div className="tags__wrapper">
          <Button
            style={{
              height: "60px",
              marginRight: ".5rem",
              backgroundColor: "#252525",
              border: "none",
            }}
            onClick={() =>
              document.getElementById("tags").scrollBy({
                left: -1000,
                behavior: "smooth",
              })
            }
          >
            <ArrowLeft />
          </Button>
          <div className="tags__container" id="tags">
            <div
              className={`tag__item ${!activeTag.length ? "active" : ""}`}
              onClick={() => changeTag("")}
            >
              All
            </div>
            <div
              className={`tag__item ${
                activeTag === "favorite" ? "active" : ""
              }`}
              onClick={() => changeTag("favorite")}
            >
              Favorite
            </div>
            {tags.map(
              (_tag, index) =>
                _tag !== "Test" &&
                _tag && (
                  <div
                    key={index}
                    className={`tag__item ${
                      activeTag === _tag ? "active" : ""
                    }`}
                    onClick={() => changeTag(_tag)}
                  >
                    {_tag}
                  </div>
                )
            )}
          </div>
          <Button
            style={{
              height: "60px",
              marginLeft: ".5rem",
              backgroundColor: "#252525",
              border: "none",
            }}
            onClick={() =>
              document.getElementById("tags").scrollBy({
                left: 1000,
                behavior: "smooth",
              })
            }
          >
            <ArrowRight />
          </Button>
        </div>
      </Container>
      <ShiftModal
        isOpen={openShift}
        toggleOpen={() => setOpenShift(false)}
        setCookies={setCookies}
      />
      <Modal
        centered
        isOpen={openShiftRunning}
        toggle={() => setOpenShiftRunning(!openShiftRunning)}
        size="md"
      >
        <ModalBody className="px-5 py-4">
          <h4 className="mb-4">{`Active Shift Running from ${shiftData?.shift_session?.shift_length} hours`}</h4>

          <div className="d-flex gap-2 cart__clear-buttons">
            <button
              className="header_menu__item w-100"
              style={{ backgroundColor: "red" }}
              onClick={() => setOpenShiftRunning(false)}
            >
              Ok
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default DiscoverMleiha;
