import React, { useState } from "react";
import { Input, Label, Form, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  getUserNames,
  getDestinations,
} from "../store/actions/authActions";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

function Login() {
  const [userName, setUserName] = useState("");
  const [userNamesList, setUserNamesList] = useState([]);
  const [destinationList, setDestinationsList] = useState([]);
  const [destination, setDestination] = useState("");
  const [password, setPassword] = useState("");
  const [is_loading, setLoading] = useState(false);
  const { loading, notification } = useSelector((state) => state.auth);
  const [cookies, setCookies] = useCookies();
  const dispatch = useDispatch();

  useEffect(() => {
    // localStorage.clear();
    document.cookie =
      "authToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    document.cookie =
      "userDetails=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    getDestinations(
      (res) => {
        console.log(res.data);
        setDestinationsList(res.data);
        setDestination(res.data[0]);
        localStorage.setItem("destination", res.data[0].url_slug);
        handleGetUserNames(res.data[0].url_slug);
      },
      (err) => console.log(err)
    );
  }, []);

  const handleGetUserNames = (slug) => {
    setLoading(true);
    getUserNames(
      slug,
      (res) => {
        setUserNamesList(res.data);
        setUserName(
          res.data.find((name, i) =>
            cookies.userName ? name === cookies.username : !i
          )
        );
        setLoading(false);
      },
      (err) => console.log(err)
    );
  };

  const onLogin = (e) => {
    e.preventDefault();
    dispatch(login({ username: userName, password: password }, setCookies));
  };

  return (
    <div className="login__wrapper">
      <div className="login__container">
        <h3>
          <b>Welcome,</b> sign in to continue.
        </h3>
        <Form onSubmit={onLogin}>
          <Label>Destinations</Label>
          <Input
            type="select"
            placeholder="destination"
            required
            value={destination}
            onChange={(e) => {
              setDestination(e.target.value);
              localStorage.setItem("destination", e.target.value);
              handleGetUserNames(e.target.value);
            }}
          >
            {destinationList.map((dest, idx) => (
              <option key={idx} value={dest.url_slug}>
                {dest.name}
              </option>
            ))}
          </Input>
          <Label>Username</Label>
          <Input
            type="select"
            placeholder="your username"
            required
            disabled={is_loading}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          >
            {userNamesList.map((name, id) => (
              <option key={id} value={name}>
                {name}
              </option>
            ))}
          </Input>

          <Label>Password</Label>
          <Input
            type="password"
            placeholder="*********"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button className="header_menu__item mt-3 w-100">
            {loading ? <Spinner size="sm" /> : null} &nbsp; Sign In
          </button>

          {notification.length ? (
            <p className="error mx-auto mt-3 mb-0">Invalid Credentials</p>
          ) : null}
        </Form>
        <small>Trouble to Login ? Contact POS Admin</small>
      </div>
    </div>
  );
}

export default Login;
