import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getBookingDetails,
  redeemOrder,
} from "../store/actions/discoverActions";
import { Modal, ModalBody } from "reactstrap";
import Comments from "../components/Comments";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import { notifyError, notifyInfo, notifySuccess } from "../utils/notifyToast";

const RedeemBooking = ({
  match: {
    params: { booking_number },
  },
}) => {
  const [bookingDetails, setBookingDetails] = useState({
    status: "idle",
    data: null,
  });
  const [redeemed, setRedeemed] = useState([]);
  const [notes, setNotes] = useState([]);
  const [orderAlert, setOrderAlert] = useState("");
  const [openOrderRedeem, setOpenOrderRedeem] = useState(false);
  const token = useSelector((state) => state.authUser.uid);

  useEffect(() => {
    handleBookingDetails();
  }, []);

  const handleBookingDetails = () => {
    getBookingDetails(
      booking_number,
      token,
      (resp) => {
        console.log(resp);
        if (!resp.success) {
          notifyError(resp.msg || "Failed to load data.");
          setOrderAlert(resp.msg);
          return;
        }
        setNotes(
          resp.data.purchase_data.internal_notes
            ? JSON.parse(resp.data.purchase_data.internal_notes)
            : []
        );
        setBookingDetails({
          status: "success",
          data: resp.data.order_data.find(
            (item) => item.order_number === booking_number
          ),
        });
      },
      (err) => {
        notifyError(err.message || "Failed to load data.");
        setBookingDetails({
          status: "failed",
          data: null,
        });
      }
    );
  };

  const onRedeem = (order_number) => {
    setOpenOrderRedeem(false);
    redeemOrder(
      order_number,
      token,
      (resp) => {
        notifySuccess("Order redeemed.");
        setRedeemed([...redeemed, order_number]);
        handleBookingDetails();
      },
      (err) => {
        notifyError(err.message || "Could not redeem. Retry.");
      }
    );
  };

  const redeemConfirmation = (order_number) => {
    if (redeemed.includes(order_number)) {
      notifyInfo("Order already redeemed.");
      return;
    }
    setOpenOrderRedeem(true);
  };

  const order = useMemo(() => bookingDetails.data, [bookingDetails.data]);
  const getStatus = useMemo(() => {
    if (!order) return "pending";
    if (order.status === "1") return "success";
    if (order.status === "2") return "failed";
    return "pending";
  }, [order]);

  const meals = useMemo(() => {
    if (!order || !order.food.length) return [0, 0];
    return order.food.split(",");
  }, [order]);

  useHotkeys(
    "enter",
    !document.getElementById("pin") &&
      openOrderRedeem &&
      onRedeem(order.order_number)
  );

  return (
    <div className="payment__container mt-4">
      <div
        className="w-100 pb-3 d-flex justify-content-between"
        style={{ borderBottom: "1px solid lightgrey" }}
      >
        <h2>Redeem order</h2>
        {order ? (
          bookingDetails.data?.redeem ? (
            <div
              className="d-flex flex-column text-muted text-align-left"
              style={{ width: 220, fontSize: 14 }}
            >
              <div>
                Redeemd on : <b>{bookingDetails.data.redeemed_time}</b>
              </div>
              <div>
                Redeemd by user : <b>{bookingDetails.data.redeemed_by}</b>
              </div>
            </div>
          ) : (
            <button className="header_menu__item" onClick={redeemConfirmation}>
              Redeem
            </button>
          )
        ) : null}
      </div>
      {bookingDetails.status === "success" ? (
        order ? (
          <div className="order__details pt-4">
            <div className="order__details--header">
              <h5>{order.package_name}</h5>
            </div>
            <div className="order__description">
              <div className="desc">
                <span>Order ID:</span>
                <span>{order.order_number}</span>
              </div>
              <div className="desc">
                <span>Ordered on:</span>
                <span>{moment(order.created_at).format("DD/MM/YYYY")}</span>
              </div>
              <div className="desc">
                <span>Order Status</span>
                <span>
                  <span className={`purchase__status ${getStatus}`}>
                    {getStatus}
                  </span>
                </span>
              </div>
              <br />
              <div className="desc">
                <span>Destination Name:</span>
                <span>{order.destination_name}</span>
              </div>
              <div className="desc">
                <span>Package Name:</span>
                <span>{order.package_name}</span>
              </div>
              {order.order_date && (
                <div className="desc">
                  <span>Date of Event:</span>
                  <span>{order.order_date}</span>
                </div>
              )}
              {order.order_timeslot && (
                <div className="desc">
                  <span>Time of Event:</span>
                  <span>{order.order_timeslot}</span>
                </div>
              )}
              <br />
              {order.notes && (
                <div className="desc">
                  <span>Notes:</span>
                  <span>{order.notes}</span>
                </div>
              )}
              <br />
              {(Number(order.adult_qty) > 0 || Number(order.child_qty) > 0) && (
                <div className="desc">
                  <span>Attendee(s):</span>
                  <span>
                    {Number(order.adult_qty) > 0 && (
                      <span>
                        {order.adult_qty} Adult{" "}
                        {Number(order.child_qty) > 0 && ","}
                      </span>
                    )}
                    {Number(order.child_qty) > 0 && (
                      <span>{order.child_qty} Child </span>
                    )}
                  </span>
                </div>
              )}
              {order.transport_id !== "0" && (
                <div className="desc">
                  <span>Transportation:</span>
                  <span>{order.transport_name}</span>
                </div>
              )}
              {order.order_meals?.length > 0 && (
                <div className="desc">
                  <span>Meal(s):</span>
                  {order.order_meals.map((meal, i) => (
                    <span>
                      {" "}
                      <span> {meal.meal_qty}</span>
                      <span> {meal.meal_name}</span>
                      {order.order_meals.length > i + 1 && ","}
                    </span>
                  ))}
                </div>
              )}
              {(order.order_sub_packages?.length > 0 ||
                order.subpackages_adult_qty?.length > 0 ||
                order.subpackages_child_qty?.length > 0) && (
                <div className="desc">
                  <span>Addon(s):</span>
                  <span>
                    {order.order_sub_packages?.length > 0 && (
                      <span>{order.order_sub_packages?.length} packages, </span>
                    )}
                    {order.subpackages_adult_qty.length > 0 && (
                      <span>
                        {order.subpackages_adult_qty.split(",").length}adult,
                      </span>
                    )}
                    {order.subpackages_child_qty.length > 0 && (
                      <span>
                        {order.subpackages_child_qty.split(",").length}child
                      </span>
                    )}
                  </span>
                </div>
              )}
              <br />
              <div className="desc">
                <span>
                  <h6 className="text-muted">Total Amount:</h6>
                </span>
                <span>
                  <b>AED {order.total}</b>
                </span>
              </div>
              <div className="mt-4">
                <h5>Internal Notes(s)</h5>
                {notes.map((note, i) => (
                  <div style={{ marginLeft: "1rem" }}>{note.comment}</div>
                ))}
              </div>
            </div>
            <Comments
              purchaseNumber={order?.purchase_number}
              handleDetails={handleBookingDetails}
            />
          </div>
        ) : (
          <div className="order__error">
            <h6>Could not find order data.</h6>
          </div>
        )
      ) : (
        <h6
          style={{
            textAlign: "center",
            marginTop: "3rem",
            fontSize: "1.5rem",
          }}
        >
          {orderAlert || "Loading data..."}
        </h6>
      )}
      <Modal
        centered
        isOpen={openOrderRedeem}
        toggle={() => setOpenOrderRedeem(!openOrderRedeem)}
        size="md"
      >
        <ModalBody className="px-5 py-4">
          <h4 className="mb-4">
            Do you want to Redeem the Order {order?.order_number}{" "}
          </h4>
          <div className="d-flex gap-2 cart__clear-buttons">
            <button
              className="header_menu__item w-50"
              style={{ backgroundColor: "red" }}
              onClick={() => setOpenOrderRedeem(!openOrderRedeem)}
            >
              Cancel
            </button>
            <button
              className="header_menu__item w-50"
              onClick={() => onRedeem(order.order_number)}
            >
              Redeem
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withRouter(RedeemBooking);
