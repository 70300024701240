import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  AUTH_LOADING,
  AUTH_NOTIFICATION,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_REQUESTED,
  UPDATE_PROFILE_REQUESTED,
  UPDATE_PROFILE_END,
  AUTH_LOGOUT,
  FETCH_PACKAGES,
} from "../types";
import { getPackages } from "../../store/actions/discoverActions";
import { notifyError, notifySuccess } from "../../utils/notifyToast";

export const setLoading = (val) => async (dispatch) => {
  dispatch({
    type: AUTH_LOADING,
    payload: val,
  });
};
export const setAuthNotification = (val) => async (dispatch) => {
  dispatch({
    type: AUTH_NOTIFICATION,
    payload: val,
  });
};

export const getDestinations = (onSuccess, onError) => {
  let form = new FormData();
  form.append("action", "listDestinations");
  axios
    .post(process.env.REACT_APP_BASE_URL, form)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((e) => {
      onError(e);
    });
};

export const getUserNames = (slug, onSuccess, onError) => {
  let form = new FormData();
  form.append("action", "listPosUsers");
  form.append("destination_slug", slug);
  axios
    .post(process.env.REACT_APP_BASE_URL, form)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((e) => {
      onError(e);
    });
};

export const login = (obj, setCookies) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let form = new FormData();
    form.append("action", "login");
    form.append("username", obj.username);
    form.append("password", obj.password);
    axios.post(process.env.REACT_APP_BASE_URL, form).then((res) => {
      if (res.data.token) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.token,
        });
        setCookies("username", jwt_decode(res.data.token).username);
        localStorage.setItem("showActiveSession", "yes");
        dispatch(setAuthNotification("Login Success"));
        window.setTimeout(() => {
          dispatch(setAuthNotification(""));
        }, 2500);
        dispatch(setLoading(false));
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch(setAuthNotification("Invalid Credentials"));
        window.setTimeout(() => {
          dispatch(setAuthNotification(""));
        }, 2500);
        dispatch(setLoading(false));
      }
    });
  } catch (e) {
    dispatch(setAuthNotification("Login Success"));
    window.setTimeout(() => {
      dispatch(setAuthNotification(""));
    }, 2500);
    dispatch(setLoading(false));
  }
};

export const relogin = (setLoading, obj, token, onSuccess, onError) => {
  setLoading(true);
  let form = new FormData();
  form.append("action", "verifyPoSUserPIN");
  form.append("username", obj.username);
  form.append("pin", obj.pin);
  axios
    .post(process.env.REACT_APP_BASE_URL, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      setLoading(false);
      onSuccess(res.data);
    })
    .catch((e) => {
      setLoading(false);
      onError(e);
    });
};

export const getUserProfile = (phone, token, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_PROFILE_REQUESTED,
    });
    const form = new FormData();
    form.append("action", "getUser");
    form.append("mobile", phone);
    const { data } = await axios.post(process.env.REACT_APP_BASE_URL, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!data.data) throw new Error("User profile fetch failed.");
    dispatch({
      type: FETCH_PROFILE_SUCCESS,
      payload: data.data,
    });
    onSuccess && onSuccess();
  } catch (err) {
    dispatch({
      type: FETCH_PROFILE_FAILED,
    });
    notifyError("Something went wrong, unable to fetch customer data");
  }
};

export const getDocuments = async (userId, token, onSuccess) => {
  try {
    const form = new FormData();
    form.append("action", "listDocuments");
    form.append("user_id", userId);
    const { data } = await axios.post(process.env.REACT_APP_BASE_URL, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    onSuccess && onSuccess(data);
  } catch (err) {
    notifyError("Something went wrong, unable to fetch Document data");
  }
};

export const addDocuments = async (userId, documents, token, onSuccess) => {
  try {
    const form = new FormData();
    form.append("action", "addDocuments");
    form.append("user_id", userId);
    documents.map((document) => {
      form.append("documents[]", document);
    });

    const { data } = await axios.post(process.env.REACT_APP_BASE_URL, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    onSuccess && onSuccess(data);
  } catch (err) {
    console.log(err);
    notifyError("Something went wrong, unable to add Document");
  }
};

export const deleteDocuments = async (
  userId,
  document_id,
  token,
  onSuccess
) => {
  try {
    const form = new FormData();
    form.append("action", "deleteDocument");
    form.append("user_id", userId);
    form.append("document_id", document_id);
    const { data } = await axios.post(process.env.REACT_APP_BASE_URL, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    onSuccess && onSuccess(data);
  } catch (err) {
    console.log(err);
    notifyError("Something went wrong, unable to delete Document");
  }
};

export const updateUserProfile = (id, userData, token) => async (dispatch) => {
  console.log("userData", userData);
  try {
    dispatch({
      type: UPDATE_PROFILE_REQUESTED,
    });
    const form = new FormData();
    form.append("action", "posUpdateUserProfile");
    form.append("id", id);
    form.append("email", userData.email);
    form.append("name", userData.name);
    form.append("meta_info", JSON.stringify(userData.meta_info));
    // form.append("nature_info", userData.nature);
    // form.append("is_tourist", userData.isTourist);
    // form.append("tourist_info", userData.tourist);
    // form.append("tourist_notes", userData.touristNotes);
    // form.append("is_resident", userData.isResident);
    // form.append("resident_info", userData.resident);
    // form.append("resident_notes", userData.residentNotes);
    // form.append("is_expat", userData.isExpat);
    // form.append("expat_info", userData.expat);
    // form.append("expat_notes", userData.expatNotes);
    // form.append("nature_of_tourist", userData.natureOfTourist);
    // form.append("nature_of_tourist_notes", userData.natureOfTouristNotes);
    // form.append("is_corporate", userData.isCorporate);
    // form.append("corporate_info", userData.corporate);
    // form.append("corporate_notes", userData.corporateNotes);
    // form.append("is_stakeholder", userData.isStakeHolder);
    // form.append("stakeholder_info", userData.stakeHolder);
    // form.append("stakeholder_notes", userData.stakeHolderNotes);
    // form.append("is_government", userData.isGovernment);
    // form.append("government_info", userData.government);
    // form.append("government_notes", userData.governmentNotes);
    // for (var pair of form.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    await axios.post(process.env.REACT_APP_BASE_URL, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    notifySuccess("Profile updated successfully");
    dispatch(getUserProfile(userData.mobile, token));
  } catch (err) {
    notifyError("Profile update failed");
  } finally {
    dispatch({
      type: UPDATE_PROFILE_END,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};
export const searchUser = async (mobile, token, onSuccess, onError) => {
  try {
    const form = new FormData();
    form.append("action", "searchUsers");
    form.append("mobile", mobile);

    const { data: userData } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    onSuccess && onSuccess(userData.data);
  } catch (err) {
    onError && onError(err);
  }
};

export const getUserDetail = async (user_id, token, onSuccess, onError) => {
  try {
    const form = new FormData();
    form.append("action", "userDetails");
    form.append("id", user_id);

    const { data: userData } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    onSuccess && onSuccess(userData.data);
  } catch (err) {
    onError && onError(err);
  }
};

export const favoriteDetail =
  (id, action, favAvailable, token, setFavAvailable, setFavLoading) =>
  async (dispatch) => {
    try {
      const form = new FormData();
      form.append("action", action);
      form.append("package_id", id);
      const data = await axios.post(process.env.REACT_APP_BASE_URL, form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFavAvailable(data.data.packageData.favorite === "1");
      notifySuccess(
        `Favorite ${
          data.data.packageData.favorite === "1" ? "added" : "removed"
        } successfully`
      );
      dispatch(getPackages(token));
      setFavLoading(false);
    } catch (err) {
      notifyError(`Favorite ${favAvailable ? "removed" : "added"} failed`);
      setFavLoading(false);
    }
  };
