import React from "react";
import DataTable from "react-data-table-component";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const getStatus = (status) => {
  if (status === "1") return "success";
  if (status === "2") return "failed";
  return "pending";
};

const purchaseTransactionDataModel = [
  {
    name: "Purchase No.",
    selector: (row) => row.purchase_number,
  },
  {
    name: "Reference No.",
    selector: (row) => row.ref_number,
  },
  {
    name: "Receipt No.",
    selector: (row) => row.receipt_number,
  },
  {
    name: "Payment Ref.",
    selector: (row) => row.payment_ref,
  },
  {
    name: "Payment Method",
    selector: (row) => row.payment_method.toUpperCase(),
    center: true,
  },
  {
    name: "Notes",
    cell: (row) => (
      <Tippy content={row.notes}>
        <div>{row.notes?.slice(0, 10)}</div>
      </Tippy>
    ),
    center: true,
  },
  {
    name: "Status",
    cell: (row) => (
      <>
        {row.status === "0" ? (
          <button
            className="header_menu__item"
            onClick={() =>
              window.open(
                row.payment_link,
                "Payment - Discover POS",
                `left=100,top=100,popup=yes,width=800px,height=800px`
              )
            }
          >
            Retry Payment
          </button>
        ) : (
          <span className={`purchase__status ${getStatus(row.status)}`}>
            {getStatus(row.status)}
          </span>
        )}
      </>
    ),
    center: true,
  },
];

const PaymentTransactionData = ({ transactionData }) => {
  return (
    <div className="transcation__data mt-5">
      <h5>Transaction Details</h5>
      <DataTable
        columns={purchaseTransactionDataModel}
        data={transactionData}
      />
    </div>
  );
};

export default PaymentTransactionData;
