import {
  FETCH_PACKAGE,
  FETCH_PACKAGES,
  FETCH_PACKAGE_PRICE,
  FETCH_TAGS,
  COUPON_VALIDITY_FAILED,
  COUPON_VALIDITY_SUCCESS,
  COUPON_VALIDITY_REQUESTED,
  FETCH_DATE_AVAILABLE,
  FETCH_SHIFT_DATA,
  FETCH_PACKAGE_SLOTS,
  BOOK_PACKAGE_FAILED,
  BOOK_PACKAGE_SUCCESS,
  BOOK_PACKAGE_REQUESTED,
  PACKAGES_FILTERED,
  SWITCH_TAG,
  FETCH_COUPONS_REQUESTED,
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAILED,
  BOOKING_CANCELLED,
  BOOKING_FINISHED,
  COUPON_SKIPPED,
} from "../types";

const initState = {
  packages: [],
  filterTerm: "",
  tags: [],
  logo: "",
  display_name: "",
  google_review_link: "",
  tripadvisor_link: "",
  activeTag: "",
  couponValidityData: [],
  couponValidityStatus: "idle",
  couponList: [],
  couponFetchStatus: "idle",
  bookingSequence: "idle",
  bookingData: null,
};

const discoverReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PACKAGES:
      return {
        ...state,
        packages: action.payload.packages,
        activeTag: state.activeTag.length ? state.activeTag : "",
      };
    case FETCH_PACKAGE_PRICE:
      return {
        ...state,
        packageDataPrice: action.payload,
      };
    case FETCH_TAGS:
      return {
        ...state,
        logo: action.payload.logo,
        tags: action.payload.tags,
        display_name: action.payload.display_name,
        google_review_link: action.payload.google_review_link,
        tripadvisor_link: action.payload.tripadvisor_link,
      };
    case SWITCH_TAG:
      return {
        ...state,
        activeTag: action.payload,
      };
    case PACKAGES_FILTERED:
      return {
        ...state,
        filterTerm: action.payload,
      };
    case FETCH_PACKAGE:
      return {
        ...state,
        package: action.payload,
      };
    case FETCH_DATE_AVAILABLE:
      return {
        ...state,
        dateAvailability: action.payload,
      };
    case FETCH_SHIFT_DATA:
      return {
        ...state,
        shiftData: action.payload,
      };
    case COUPON_SKIPPED:
      return {
        ...state,
        couponValidityData: [],
        bookingSequence: "in-progress",
      };
    case BOOKING_FINISHED:
      return {
        ...state,
        couponValidityData: [],
        bookingSequence: "completed",
      };
    case BOOKING_CANCELLED:
      return {
        ...state,
        couponValidityData: [],
        bookingSequence: "cancelled",
      };
    case FETCH_COUPONS_SUCCESS:
      return {
        ...state,
        couponList: action.payload,
        couponFetchStatus: "success",
      };
    case FETCH_COUPONS_REQUESTED:
      return {
        ...state,
        couponList: [],
        couponFetchStatus: "requested",
      };
    case FETCH_COUPONS_FAILED:
      return {
        ...state,
        couponList: [],
        couponFetchStatus: "failed",
      };
    case COUPON_VALIDITY_REQUESTED:
      return {
        ...state,
        couponValidityData: null,
        couponValidityStatus: "requested",
      };
    case COUPON_VALIDITY_FAILED:
      return {
        ...state,
        couponValidityData: null,
        couponValidityStatus: "failed",
      };
    case COUPON_VALIDITY_SUCCESS:
      return {
        ...state,
        couponValidityData: action.payload,
        couponValidityStatus: "success",
        bookingSequence: "in-progress",
      };
    case FETCH_PACKAGE_SLOTS:
      return {
        ...state,
        packageSlots: action.payload,
      };
    case BOOK_PACKAGE_REQUESTED:
      return {
        ...state,
        bookingPackageStatus: "requested",
      };
    case BOOK_PACKAGE_FAILED:
      return {
        ...state,
        bookingPackageStatus: "warning",
        bookingData: null,
      };
    case BOOK_PACKAGE_SUCCESS:
      return {
        ...state,
        bookingPackageStatus: "success",
        bookingData: action.payload,
        bookingSequence: "completed",
      };
    default:
      return {
        ...state,
      };
  }
};
export default discoverReducer;
