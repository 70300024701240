import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Input, Label, Modal, ModalBody } from "reactstrap";
import { notifyError } from "../../utils/notifyToast";
import { useHotkeys } from "react-hotkeys-hook";

const RedeemOrderModal = ({ isOpen, toggleOpen }) => {
  const [orderNumber, setOrderNumber] = useState("");
  const inputRef = useRef(null);
  const history = useHistory();

  const onViewOrder = () => {
    if (!orderNumber.length) {
      notifyError("Enter order number.");
      return;
    }
    toggleOpen();
    history.push("/order-details/" + orderNumber.toUpperCase());
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onViewOrder();
    }
  };

  useHotkeys("enter", (event) => {
    if (
      document.activeElement !== inputRef.current &&
      !document.getElementById("pin") &&
      isOpen
    ) {
      event.preventDefault();
      onViewOrder();
    }
  });

  return (
    <Modal
      centered
      autoFocus={false}
      isOpen={isOpen}
      toggle={toggleOpen}
      size="md"
    >
      <ModalBody className="px-5 py-4">
        <h4 className="mb-4">Open Order Details to Redeem</h4>

        <Label>Order Number</Label>
        <Input
          ref={inputRef}
          autoFocus
          placeholder="XXXXXX"
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
          onKeyDown={handleKeyPress}
        />

        <div className="d-flex gap-2 cart__clear-buttons">
          <button
            className="header_menu__item mt-3 w-100"
            onClick={onViewOrder}
          >
            View Order Details
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RedeemOrderModal;
