import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { shiftSession, getShiftData } from "../store/actions/discoverActions";
import DataTable from "react-data-table-component";
import moment from "moment";

const getStatus = (status) => {
  if (status) return "shift running";
  if (!status) return "shift ended";
};

function ShiftSummary() {
  const [endShiftStatus, setEndShiftStatus] = useState(false);
  const dispatch = useDispatch();
  const totalDataColumns = [
    {
      name: "Total Booking",
      selector: (row) => row.total_booking,
      center: true,
    },
    {
      name: "Total Orders",
      selector: (row) => row.total_ecomm_orders,
      center: true,
    },
    {
      name: "Total Purchase",
      cell: (row) => row.total_purchases,
      center: true,
    },
  ];
  const paymentColumns = [
    {
      name: "Card",
      selector: (row) => row.card_manual,
      center: true,
    },
    {
      name: "Cash",
      selector: (row) => row.cash,
      center: true,
    },
    {
      name: "Pay Later",
      cell: (row) => row.paylater,
      center: true,
    },
  ];
  const bookingsColumns = [
    {
      name: "Id",
      selector: (row) => row.package_id,
      center: true,
    },
    {
      name: "Package Name",
      selector: (row) => row.package_name,
      center: true,
    },
    {
      name: "Adults",
      selector: (row) => row.adults,
      center: true,
    },
    {
      name: "Bookings",
      selector: (row) => row.bookings,
      center: true,
    },
    {
      name: "Childs",
      cell: (row) => row.childs,
      center: true,
    },
    {
      name: "Total Amount",
      cell: (row) => row.total_amount,
      center: true,
    },
  ];
  const sessionColumns = [
    {
      name: "Start Time",
      selector: (row) => moment(row.start_time).format("DD/MM/YYYY"),
      center: true,
    },
    {
      name: "End Time",
      selector: (row) =>
        row.end_time ? moment(row.end_time).format("DD/MM/YYYY") : "",
      center: true,
    },
    {
      name: "Duration",
      cell: (row) => row.shift_length,
      center: true,
    },
    {
      name: "Status ",
      cell: (row) => (
        <span className={`purchase__status ${getStatus(row.status)}`}>
          {getStatus(row.status)}
        </span>
      ),
      center: true,
    },
  ];
  const [sessionData, setSessionData] = useState([]);

  const summaryColumns = [];
  const { shiftData, token } = useSelector(({ discover, authUser }) => ({
    shiftData: discover.shiftData,
    token: authUser.uid,
  }));

  useEffect(() => {
    if (!shiftData)
      dispatch(
        getShiftData(token, "checkForErr", "active_shift_status", (res) =>
          console.log(res)
        )
      );
  }, []);

  useEffect(() => {
    if (shiftData?.shift_session) {
      setSessionData([shiftData.shift_session]);
    }
  }, [shiftData]);

  const endShift = () => {
    shiftSession(token, "shift_end", (response) => {
      setSessionData([response.shift_session]);
      setEndShiftStatus(false);
      localStorage.setItem("shift", "");
    });
  };

  useEffect(() => {
    if (localStorage.getItem("shift")) {
      setEndShiftStatus(true);
    } else {
      setEndShiftStatus(false);
    }
  }, [shiftData]);

  return (
    <>
      <Container className="mt-4 mb-5 pb-5">
        {shiftData?.success > 0 && (
          <div className="purchase__data mt-5">
            <h5 className="mb-2 mt-2">Purchase Summary</h5>
            <DataTable
              columns={totalDataColumns}
              data={[shiftData.shift_session_purchaes_summary]}
            />
            <h5 className="mb-2 mt-4">Payment Summary</h5>
            <DataTable
              columns={paymentColumns}
              data={[shiftData.shift_session_finance_summery]}
            />
            <h5 className="mb-2 mt-4">Summary</h5>
            <DataTable
              columns={summaryColumns}
              data={[shiftData.shift_session_ecom_summery]}
            />
            <h5 className="mb-2 mt-4">Booking Summary</h5>
            <DataTable
              columns={bookingsColumns}
              data={shiftData.shift_session_bookings_summery}
            />
            <h5 className="mb-2 mt-4">Session Summary</h5>
            <DataTable columns={sessionColumns} data={sessionData} />
          </div>
        )}
        {endShiftStatus && (
          <div
            className="mt-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button className="header_menu__item" onClick={endShift}>
              End Shift
            </button>
          </div>
        )}
      </Container>
    </>
  );
}

export default ShiftSummary;
