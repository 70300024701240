import { useMemo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initiatePayment,
  authorizePayLater,
  getTags,
} from "../store/actions/discoverActions";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { notifyInfo, notifyWarn } from "../utils/notifyToast";
import { useHotkeys } from "react-hotkeys-hook";
import { OPEN_WINDOW } from "../store/types";
import NewWindow from "react-new-window";

const PaymentUserData = ({ userData, status, orderData, purchaseResponse }) => {
  const { token, userDetails } = useSelector(({ authUser, discover }) => ({
    token: authUser.uid,
    userDetails: authUser.userDetails,
  }));
  const [openPayMethodModal, setOpenPayMethodModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();
  const [paymentJSON, setPaymentJSON] = useState([]);

  useEffect(() => {
    dispatch(getTags(token));
  }, []);

  const getStatus = useMemo(() => {
    if (status === "1") return "success";
    if (status === "2") return "failed";
    return "pending";
  }, [status]);

  const onRetryPayment = () => {
    if (!paymentMethod) {
      notifyWarn("Please choose payment method");
      return;
    }
    notifyInfo("Initiating Payment retry.");
    setOpenPayMethodModal(false);
    dispatch(
      initiatePayment(
        localStorage.getItem("shift"),
        purchaseResponse,
        token,
        paymentMethod,
        `${window.location.origin}/closewindow`,
        false,
        (paymentResponse) => {
          window.open(
            paymentResponse.payment_link,
            "Payment - Discover POS",
            `left=100,top=100,popup=yes,width=800px,height=800px`
          );
        }
      )
    );
  };

  useEffect(() => {
    if (openPayMethodModal)
      authorizePayLater(userDetails.id, token, (authorizeResponse) => {
        var paymentValues = [
          { title: "CARD", value: "card_manual" },
          { title: "CASH", value: "cash" },
          { title: "BANK TRANSFER", value: "bank-transfer" },
          { title: "CHEQUE", value: "cheque" },
          { title: "CUSTOM PAYMENTS", value: "custom-payments" },
        ];
        if (authorizeResponse) {
          setPaymentJSON([
            ...paymentValues,
            { title: "PAY LATER", value: "paylater" },
          ]);
        } else {
          setPaymentJSON(paymentValues);
        }
      });
  }, [openPayMethodModal]);

  const handleOpenWindow = () => {
    const popupWidth = 800;
    const popupHeight = 600;
    const leftPosition = (window.screen.width - popupWidth) / 2;
    const topPosition = (window.screen.height - popupHeight) / 2;
    const popupWindow = window.open(
      `${window.location.origin}/printReceipt/${orderData[0].purchase_number}`,
      "_blank",
      `width=${popupWidth},height=${popupHeight},left=${leftPosition},top=${topPosition}`
    );
    if (popupWindow) {
      popupWindow.focus();
    } else {
      alert("Popup window blocked. Please allow popups for this site.");
    }
  };

  useHotkeys(
    "enter",
    !document.getElementById("pin") && openPayMethodModal && onRetryPayment
  );

  return (
    <div className="user__data">
      <div className="user__info">
        <div className="user__data--name">{userData?.name}</div>
        <div className="user__data--email">
          <b>Email: </b>
          {userData?.email}
        </div>
        <div className="user__data--mobile">
          <b>Mobile: </b>
          {userData?.mobile}
        </div>
      </div>
      <div className="status" style={{ display: "flex", gap: "1rem" }}>
        {getStatus === "failed" || getStatus === "pending" ? (
          <button
            className="header_menu__item"
            onClick={() => setOpenPayMethodModal(true)}
          >
            Retry Payment
          </button>
        ) : null}
        {getStatus === "success" ? (
          <button className="header_menu__item" onClick={handleOpenWindow}>
            Print Invoice
          </button>
        ) : null}
        {getStatus === "pending" ? (
          <>
            <div className={`purchase__status ${getStatus}`}>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>{" "}
              Waiting for payment
            </div>
          </>
        ) : null}
        {getStatus === "success" ? (
          <div className={`purchase__status ${getStatus}`}>{getStatus}</div>
        ) : null}
      </div>
      <Modal
        centered
        isOpen={openPayMethodModal}
        toggle={() => setOpenPayMethodModal(!openPayMethodModal)}
        size="lg"
        scrollable
      >
        <ModalHeader toggle={() => setOpenPayMethodModal(!openPayMethodModal)}>
          Choose Payment Method
        </ModalHeader>
        <ModalBody>
          <div className="payment-method" style={{ width: "100%" }}>
            {paymentJSON.map((payment) => (
              <label className="payment-card">
                <input
                  name="plan"
                  className="radio"
                  type="radio"
                  value={payment.value}
                  onClick={(e) => setPaymentMethod(e.target.value)}
                />

                <span className="payment-details">
                  <span className="payment-type">{payment.title}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="payment-icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </label>
            ))}
          </div>
          <button
            className="header_menu__item w-40"
            style={{ marginLeft: "auto" }}
            onClick={onRetryPayment}
          >
            Confirm Payment
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentUserData;
