import React, { useRef, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { useDebouncedCallback } from "use-debounce";
import UserSearchTable from "../components/UserSearchTable";
import { searchUser } from "../store/actions/authActions";
import { useHotkeys } from "react-hotkeys-hook";

const FindUser = () => {
  const [mobile, setMobileNumber] = useState("");
  const [userData, setUserData] = useState({ status: "idle", list: [] });
  const inputRef = useRef(null);
  const token = useSelector((state) => state.authUser.uid);

  //   useEffect(() => {
  //     if (!mobile.length) {
  //       setUserData({ list: [], status: "idle" });
  //       return;
  //     }
  //     // eslint-disable-next-line
  //   }, [mobile]);

  const search = useDebouncedCallback(() => {
    if (!mobile.length) {
      setUserData({ list: [], status: "idle" });
      return;
    }
    setUserData({ list: [], status: "requested" });
    searchUser(
      mobile,
      token,
      (list) => {
        setUserData({ status: "success", list });
      },
      () => {
        setUserData({ status: "failed", list: [] });
      }
    );
  }, 100);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      search();
    }
  };

  useHotkeys("enter", (event) => {
    if (
      document.activeElement !== inputRef.current &&
      !document.body.classList.contains("modal-open") &&
      !document.getElementById("sidebar") &&
      !document.getElementById("pin")
    ) {
      event.preventDefault();
      search();
    }
  });

  return (
    <div className="payment__container mt-5 ps-5">
      <h5>Search Users</h5>
      <div
        className="d-flex align-items-center"
        style={{ borderBottom: "1px solid #333" }}
      >
        <IoSearchSharp />
        <Input
          ref={inputRef}
          value={mobile}
          onChange={(e) => setMobileNumber(e.target.value)}
          style={{ width: "100%", height: 40, color: "black" }}
          placeholder="Enter mobile number"
          className="search__field user"
          onKeyDown={handleKeyPress}
        />
        <div class="input-group-append">
          <button className="header_menu__item" onClick={search}>
            Search
          </button>
        </div>
      </div>
      <UserSearchTable userList={userData.list} status={userData.status} />
    </div>
  );
};

export default FindUser;
